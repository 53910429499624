/* Templates */
.template {
  margin: auto;
  width: 50%;
  margin-top: 100px;
  border: 1px solid #f0f2f3;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
}
section {
  /* padding: 0px !important; */
}
.template_section {
  /* min-height: 100vh; */
}
.template label {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
}
.template .input-group .form-control:focus {
  box-shadow: none;
  border: none;
}
.template .input-group .form-control {
  border: none;
  color: #212529;
  font-size: 18px;
  font-weight: 450;
  height: auto;
}
.template .input-group .input-group-text {
  border: none;
  background-color: #fff;
}
.template .input-group {
  border: 1px solid #d1d4d7;
  border-radius: 10px;
  padding: 5px;
}
.template .input-group img {
  border: 1px solid #d1d4d7;
  border-radius: 100px;
  padding: 10px;
  box-shadow: 1px 1px 2px 0 rgba(209, 212, 215, 0.5),
    2px 2px 7px 0 rgba(0, 0, 0, 0.1);
}
.template .webinar_forms {
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  padding: 20px;
  background: #e5fdff;
  justify-content: center;
}

.template .webinar_forms h2 {
  font-weight: 700;
  font-size: 20px;
  color: #001f2a;
  /* margin-bottom: 0px; */
}
.template .webinar_forms p {
  font-weight: 500;
  font-size: 15px;
  color: #3e545c;
  margin-bottom: 0px;
}
.template_section .webinar_time_update {
  margin-top: 100px;
  background: #e5fdff;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: center;
  margin-bottom: 10px;
}
.template_section .webinar_time_update p {
  font-weight: 700;
  font-size: 15px;
  line-height: 187%;
  color: #3e545c;
  margin-bottom: 0px;
}
.template_section .webinar_time_update span {
  font-weight: 500;
  color: #7e868d;
}
.template_section .webinar_button {
  border-radius: 100px;
  padding: 10px 20px;
  background: #001f2a;
  font-weight: 600;
  font-size: 16px;
  border: none;
  color: #eff1f2;
  display: flex;
  align-items: center;
  gap: 10px;
}
.template_section .webinar_button:hover {
  background: #7df9ff;
  color: #001f2a;
}
.template_section .webinar_button:hover img {
  filter: grayscale(100%) brightness(0) contrast(100%);
}
.template_section .webinar_countdown {
  display: flex;
  align-items: center;
  gap: 30px;
  border-radius: 20px;
  padding: 20px;
  background: #7df9ff;
}
.template_section .webinar_countdown .time-sec {
  text-align: center;
}
.template_section .webinar_countdown .time-sec h3 {
  font-weight: 600;
  font-size: 48px;
  color: #001f2a;
}
.template_section .webinar_countdown .time-sec span {
  font-weight: 500;
  font-size: 16px;
  color: #3e545c;
  text-align: center;
}
.template_section .webinar_countdown span {
  font-weight: 600;
  font-size: 48px;
  color: #001f2a;
  margin-top: -40px;
}

.template .container-fluid {
  /* padding-left: 0;
    padding-right: 0; */
}
.template_section .webinar_timer h3 {
  font-weight: 600;
  font-size: 48px;
  color: #001f2a;
  margin-bottom: 0px;
}
.template_section .webinar_timer p {
  font-weight: 500;
  font-size: 16px;
  color: #3e545c;
  margin-bottom: 0px;
}
.template_section .webinar_timer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 992px) {
  .template {
    width: 100%;
  }
  .template_section .webinar_time_update {
    flex-direction: column;
  }
}
@media (max-width: 575px) {
  .template {
    margin-top: 65px;
  }
  .template_section .webinar_time_update {
    margin-top: 65px !important;
  }
  .template_section .webinar_countdown {
    display: flex;
    align-items: center;
    gap: 15px;
    border-radius: 20px;
    padding: 15px;
    background: #7df9ff;
  }
  .template_section .webinar_countdown .time-sec span {
    font-size: 13px;
  }
  .template_section .webinar_timer h3 {
    font-size: 30px !important;
  }
  .template_section .webinar_timer p {
    font-size: 14px;
  }
  .template_section .webinar_countdown span {
    font-size: 30px;
  }
}
.template_nav .btn-success.focus,
.btn-success:focus {
  background-color: #001f2a;
  border: 1px solid transparent;
  color: #fff !important;
}
.template_nav .btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background-color: #001f2a;
}
.template_nav .dropdown-menu {
  width: 100% !important;
  min-width: 200px;
}
.template_nav .dropdown-menu.show {
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border: 1px solid #f0f2f3;
  border-radius: 10px;
  padding: 10px;
  margin-left: -40px;
}

.template_nav .dropdown-item {
  font-weight: 600;
  font-size: 14px;
  color: #7e868d;
  padding: 10px;
}
.template_nav .dropdown-item:hover {
  color: #001f2a;
  background-color: #e5fdff;
  border-radius: 5px;
}
.canvaTemplateMainBox > div{
  min-height: 100vh !important;
}
.canvaTemplateMainBox > div:last-child{
  display: none;
}
.template_nav {
  background-color: #ffffff !important;
  border-bottom: 1px solid #E8ECF0;
}
.template_nav .brand_logo{
  max-width: 108px
}
/* template_1 */
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
.template1-font {
  font-family: "Manrope";
}
.temp_1 {
  background: url(./assets/images/template_img/ban_img.png) no-repeat center;
  background-size: cover;
  min-height: 100vh;
}
.temp_1 .Whitebox {
  background-color: #fff;
  padding: 40px;
  margin-top: 70px;
  max-width: 491px;
  border-radius: 20px;
}
.temp_1 .Whitebox h4 {
  font-family: "Figtree" !important;
  font-weight: 700;
  font-size: 29px;
  line-height: 34px;
  text-transform: capitalize;
  color: #e00964;
  margin-bottom: 0;
  padding-bottom: 20px;
}
.temp_1 .Whitebox p {
  margin-bottom: 0;
  /* padding-bottom: 30px; */
  font-weight: 500;
  font-size: 16px;
  line-height: 162%;
  color: #4b4b4b;
}
.temp_1 .Whitebox h3 {
  font-weight: 600;
  font-size: 48px;
  line-height: 130%;
  color: #e00964;
}
.temp_1 .webinar_timer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.temp_1 .Whitebox ul li {
  font-weight: 500;
  font-size: 16px;
  line-height: 200%;
  color: #4b4b4b;
}
.temp_1 .Whitebox button {
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  background: #e00964;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  color: #fff;
  border: 1px solid transparent;
  margin-top: 30px;
}
.temp_1 .Whitebox button:hover {
  background: transparent;
  color: #000;
  border: 1px solid #e00964;
}
.temp_1 #normal-countdown {
  backdrop-filter: blur(25px);
  background: rgba(211, 76, 76, 0.09);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 20px;
  /* display: flex;
  gap: 40px;
  justify-content: center; */
}
.temp_1 #normal-countdown .main-time {
  font-weight: 600;
  font-size: 48px;
  color: #e00964;
}
.temp_1 #normal-countdown .time-sec {
  font-weight: 500;
  font-size: 16px;
  color: #4b4b4b;
  text-align: center;
}
.temp_1 #normal-countdown .webinar_time_update {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media (max-width: 1199px) {
  .temp_1 .Whitebox h4 {
    font-size: 25px;
  }
  .temp_1 .Whitebox p {
    font-size: 14px;
  }
  .temp_1 .Whitebox h6 {
    font-size: 18px;
  }
  .temp_1 .Whitebox ul li {
    font-size: 14px;
  }
  .temp_1 #normal-countdown {
    padding: 10px;
  }
  .temp_1 #normal-countdown .main-time {
    font-size: 34px;
  }
  .temp_1 .Whitebox button {
    padding: 15px;
  }
}
@media (max-width: 991px) {
  .temp_1 .Whitebox {
    margin-top: 40px;
  }
}
@media (max-width: 575px) {
  .temp_1 .Whitebox h4 {
    font-size: 18px;
  }
  .temp_1 .Whitebox p {
    font-size: 13px;
  }
  .temp_1 .Whitebox h6 {
    font-size: 15px;
  }
  .temp_1 .Whitebox ul li {
    font-size: 11px;
  }
  .temp_1 #normal-countdown .main-time {
    font-size: 15px;
  }
  .temp_1 .Whitebox button {
    padding: 13px;
  }
  .temp_1 #normal-countdown .time-sec {
    font-size: 12px;
  }
  .temp_1 .Whitebox h3 {
    font-size: 27px;
  }
  .template_nav .brand_logo {
    width: 120px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
.template2-font {
  font-family: "Manrope";
}
.temp_2 {
  background: url(./assets/images/template_img/temp2-bg.png) no-repeat center;
  background-size: cover;
  min-height: 100vh;
}
.temp_2_head {
  padding-top: 50px;
}
.temp_2_head h2 {
  font-weight: 800;
  font-size: 52px;
  text-transform: capitalize;
  text-align: center;
  color: #fff;
  margin-bottom: 0px;
  padding-bottom: 30px;
}
.temp_2_head p {
  font-weight: 500;
  font-size: 16px;
  line-height: 162%;
  margin: auto;
  color: #fff;
  margin-bottom: 0px;
  max-width: 56%;
  text-align: center;
}
.temp_2_box {
  border-radius: 10px;
  padding: 40px;
  background: rgba(255, 255, 255, 0.15);
  max-width: 70%;
  margin: auto;
  margin-top: 50px;
}
.temp_2_box .meeting-schedule h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 108%;
  color: #faff00;
  margin-bottom: 0px;
  padding-bottom: 20px;
}
.temp_2_box .meeting-schedule ul {
  margin-bottom: 0px;
  padding-bottom: 20px;
}
.temp_2_box .meeting-schedule li {
  font-weight: 500;
  font-size: 16px;
  line-height: 200%;
  color: #fff;
}
.temp_2_box .profile-details {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.temp_2_box .profile-details h6 {
  font-weight: 600;
  font-size: 16px;
  color: #faff00;
}
.temp_2_box .profile-details p {
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  color: #fff;
}
.temp_2_box #normal-countdown {
  /* display: flex;
  gap: 40px; */
  border-radius: 5px;
  padding: 20px;
  background: #faff00;
  max-width: 90%;
}
.temp_2_box .webinar_countdown {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.temp_2_box .webinar_timer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.temp_2_box .webinar_timer p {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  color: #3c4043;
}
.temp_2_box .webinar_timer h3 {
  font-weight: 600;
  font-size: 40px;
  color: #000;
}
/* .temp_2_box .time-sec .main-time {
  font-weight: 600;
  font-size: 48px;
  color: #000;
}
.temp_2_box .time-sec span {
  font-weight: 500;
  font-size: 16px;
  color: #3c4043;
  display: flex;
  justify-content: center;
} */
.temp_2 .btn-enroll {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.temp_2 .btn-enroll button {
  border: 1px solid #faff00;
  border-radius: 10px;
  padding: 15px 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  color: #faff00;
  background: transparent;
}
.temp_2 .btn-enroll button:hover {
  background: #faff00;
  color: rgb(0, 0, 0);
}
.temp_2 .webinar_countdown span {
  font-weight: 600;
  font-size: 40px;
  color: #000;
  margin-top: -40px;
}
@media (max-width: 1199px) {
  .temp_2_box .time-sec .main-time {
    font-size: 39px;
  }
  .temp_2_box {
    max-width: 85%;
  }
}
@media (max-width: 991px) {
  .temp_2_head p {
    max-width: 100%;
  }
  .temp_2_box .meeting-schedule h4 {
    text-align: center;
  }
  .temp_2_box .meeting-schedule ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
  }
  .temp_2_box #normal-countdown {
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .temp_2_head p {
    font-size: 15px;
  }
  .temp_2_head h2 {
    font-size: 38px;
  }
}
@media (max-width: 575px) {
  .temp_2_head h2 {
    font-size: 20px;
  }
  .temp_2_head p {
    font-size: 11px;
  }
  .temp_2_box {
    padding: 22px;
  }
  .temp_2_box .meeting-schedule h4 {
    font-size: 21px;
  }
  .temp_2_box .meeting-schedule li {
    font-size: 12px;
  }
  .temp_2_box .time-sec .main-time {
    font-size: 25px;
  }
  .temp_2_box .time-sec span {
    font-size: 13px;
  }
  .temp_2_box .profile-details {
    flex-direction: column;
    text-align: center;
  }
  .temp_2_box .profile-details p {
    font-size: 11px;
  }

  .temp_2 .webinar_countdown h3 {
    font-size: 25px;
  }
  .temp_2 .webinar_countdown span {
    margin-top: -30px;
    font-size: 25px;
  }
  .temp_2_box .webinar_timer p {
    font-size: 12px;
  }
}

/* template_3 */
.template_3 * {
  font-family: "Gwen Text-Trial", sans-serif;
}

.template_3_img {
  margin-top: 30px;
}
.template_content_3 h2 {
  font-weight: 600;
  font-size: 52px;
  text-transform: capitalize;
  color: #c5673d;
  margin-bottom: 0px;
}
.template_content_3 p {
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #040302;
  margin-bottom: 0px;
  margin-top: 30px;
}
.template_3_col {
  padding: 0px;
}
.template_3_img {
  width: 100%;
  height: auto; /* Adjust as needed */
}
.template_content_3 {
  padding-top: 50px;

  padding-left: 100px;
  width: 93%;
}
.webinar_countdown_1 {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 20px;
  padding: 20px;
  background: #040302;
  width: 90%;
  justify-content: space-between;
  margin-top: 30px;
}
.webinar_countdown_1 .time-sec {
  text-align: center;
}
.webinar_countdown_1 .webinar_timer h3 {
  font-weight: 600;
  font-size: 48px;
  color: #ffcdad;
}
.webinar_countdown_1 .time-sec p {
  /* font-weight: 500;
    font-size: 16px;
    color: #d4d4d4; */
}
.webinar_countdown_1 .webinar_timer p {
  font-weight: 500;
  font-size: 16px;
  color: #d4d4d4;
}
.webinar_countdown_1 span {
  font-weight: 600;
  font-size: 48px;
  color: #ffcdad;
  margin-top: -40px;
}
.template_3_btn {
  border-radius: 100px;
  padding: 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  color: #040302;
  margin-top: 30px;
  background: linear-gradient(
    100deg,
    rgba(255, 205, 173, 1) 10%,
    rgba(197, 103, 61, 1) 100%
  );
  border: none;
  width: 90%;
}
.template_3_btn:hover {
  background: linear-gradient(
    100deg,
    rgba(197, 103, 61, 1) 0%,
    rgba(255, 205, 173, 1) 100%
  );
}
@media (max-width: 1399px) {
  .template_content_3 {
    width: 100%;
  }
  .webinar_countdown_1 {
    width: 100%;
  }
  .template_3_btn {
    width: 100%;
  }
}
@media (max-width: 1199px) {
  .template_content_3 {
    padding-top: 110px;

    padding-left: 60px;
    width: 93%;
  }
}
@media (max-width: 991px) {
  .template_3_img {
    display: none;
  }
  .template_content_3 {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 0px;
    width: 100%;
    text-align: center;
  }
  .webinar_countdown_1 {
    width: 80%;
    margin: auto;
    margin-top: 20px;
  }
  .template_3_btn {
    width: 80%;
  }
}
@media (max-width: 575px) {
  .template_content_3 {
    padding: 20px 15px 20px 15px;
  }
  .template_content_3 p {
    font-size: 16px;
  }
  .webinar_countdown_1 .webinar_timer p {
    font-size: 13px;
  }

  .webinar_countdown_1 .webinar_timer h3 {
    font-size: 30px;
  }

  .webinar_countdown_1 {
    width: 100%;
  }
  .template_3_btn {
    width: 100%;
  }
  .webinar_countdown_1 .time-sec h3 {
    font-weight: 600;
    font-size: 34px;
    color: #ffcdad;
  }
  .template_content_3 h2 {
    font-size: 35px;
  }
  .webinar_countdown_1 .time-sec span {
    font-weight: 500;
    font-size: 14px;
    color: #d4d4d4;
  }
  .webinar_countdown_1 span {
    font-weight: 600;
    font-size: 30px;
    color: #ffcdad;
    margin-top: -55px;
  }
}

/* template_4 */
.template_4 * {
  font-family: "Montserrat", sans-serif;
}
.template_4 {
  padding-top: 90px;
  padding-bottom: 90px;
}
.template_4_content span {
  font-weight: 600;
  font-size: 20px;
  color: #6c6d6d;
}
.template_4_content h2 {
  font-weight: 700;
  font-size: 72px;
  color: #000;
  margin-bottom: 0px;
}
.template_4_content p {
  font-weight: 500;
  font-size: 16px;
  line-height: 162%;
  color: #6c6d6d;
  margin-bottom: 0px;
  max-width: 70%;
  margin-top: 20px;
}
.webinar_countdown_2 {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 90%;
  margin-top: 30px;
}
.webinar_countdown_2 .time-sec {
  text-align: center;
  border-radius: 20px;
  padding: 10px 29px;
  background: #d2ffef;
}
.webinar_countdown_2 .time-sec h3 {
  font-weight: 600;
  font-size: 48px;
  color: #000;
  margin-bottom: 0px;
}
.webinar_countdown_2 .time-sec p {
  font-weight: 500;
  font-size: 16px;
  color: #292d32;
  margin-bottom: 0px;
}
.webinar_countdown_2 .time-sec span {
  display: block;
}
.webinar_countdown_2 span {
  display: none;
}
.template_4_btn {
  margin-top: 30px;
}
.template_4_btn button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

button.learn-more {
  width: 225px;
  height: auto;
}

button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #1effae;
  border-radius: 1.625rem;
}

button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  right: 24px;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -4px;
  right: 0px;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #000;
  border-right: 0.125rem solid #000;
  transform: rotate(45deg);
}

button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: -75px;
  right: 0;
  bottom: 0;
  padding: 9px;
  margin: 0 0 0 1.85rem;
  font-weight: 600;
  font-size: 20px;
  color: #000;
  text-align: center;
}

button:hover .circle {
  width: 92%;
}

button:hover .circle .icon.arrow {
  background: #000;
  transform: translate(1rem, 0);
  right: 40px;
}

button:hover .button-text {
  color: #000;
}

@media (max-width: 1399px) {
  .webinar_countdown_2 .time-sec {
    padding: 8px 24px;
  }
  .webinar_countdown_2 {
    gap: 5px;
  }
}
@media (max-width: 991px) {
  .template_4_img {
    display: none;
  }
  .template_4_content {
    text-align: center;
  }
  .template_4_content p {
    max-width: 100%;
  }
  .webinar_countdown_2 {
    justify-content: center;
    width: 100%;
    gap: 10px;
  }
  .template_4_btn {
    text-align: center;
  }
  .template_4 {
    padding: 50px 50px;
  }
}
@media (max-width: 575px) {
  .template_4 {
    padding: 30px 30px;
  }
  .template_4_content h2 {
    font-size: 40px;
  }
  .webinar_countdown_2 .time-sec h3 {
    font-size: 32px;
  }
  .template_4_content p {
    font-size: 15px;
  }
  .template_4_content span {
    font-size: 18px;
  }
  .webinar_countdown_2 .time-sec {
    padding: 5px 20px;
  }
  .webinar_countdown_2 {
    gap: 5px;
    /* margin-top: 20px; */
  }
  .webinar_countdown_2 .time-sec span {
    font-size: 14px;
  }
  /* .template_4_content p{
        margin-top: 10px;
    }
    .template_4_btn{
        margin-top: 20px;
    } */
}

/* template_5 */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
.template5-font {
  font-family: "Manrope";
}
.temp_5 {
  padding: 60px 60px !important;
}
.temp_5_head {
  padding-bottom: 54px;
}

.temp_5 p {
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: #282828;
  text-align: center;
}
.temp_5 h2 {
  font-weight: 800;
  font-size: 48px;
  text-transform: capitalize;
  text-align: center;
  color: #ce7002;
  max-width: 92%;
  margin: auto;
}
.temp_5 .row_content {
  display: flex;
  gap: 50px;
  align-items: flex-start;
}
.temp_5 .video_content {
  width: 60%;
}
.temp_5 .sample_vdo {
  width: 100%;
  height: 100%;
}
.temp_5 .orange_box {
  border-radius: 20px;
  padding: 40px;
  background: #fff6eb;
  width: 40%;
}
.temp_5 .orange_box h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 108%;
  color: #000;
  margin-bottom: 0;
  padding-bottom: 20px;
}
.temp_5 .orange_box ul {
  padding-bottom: 30px;
}
.temp_5 .orange_box ul li {
  font-weight: 500;
  font-size: 14px;
  line-height: 171%;
  color: #282828;
  padding-bottom: 10px;
}
.temp_5 .btn-orange {
  text-align: center;
}
.temp_5 .orange_box button {
  border: 1px solid rgba(206, 112, 2, 0.39);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 2px 2px 4px 0 rgba(255, 152, 33, 0.75),
    4px 4px 50px 0 rgba(255, 152, 33, 0.75);
  background: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  color: #ce7002;
  width: 100%;
}
.temp_5 .orange_box button:hover {
  box-shadow: none;
  background-color: #ce7002;
  color: #000;
}
@media (max-width: 1399px) {
  .temp_5 h2 {
    font-size: 42px;
  }
  .temp_5 .video_content {
    width: 50%;
  }
  .temp_5 .orange_box {
    width: 50%;
  }
}
@media (max-width: 1199px) {
  .temp_5 .temp_5 h2 {
    font-size: 35px;
  }
  .temp_5 .orange_box h5 {
    font-size: 21px;
  }
}
@media (max-width: 991px) {
  .temp_5 .row_content {
    flex-direction: column;
  }
  .temp_5 .video_content {
    width: 100%;
  }
  .temp_5 .orange_box {
    width: 100%;
  }
  .temp_5 .temp_5 h2 {
    font-size: 27px;
  }
  .temp_5 .temp_5 p {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .temp_5 .temp_5 p {
    font-size: 12px;
  }
  .temp_5 .temp_5 h2 {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .temp_5 .temp_5 h2 {
    font-size: 15px;
    max-width: 100%;
  }
  .temp_5 .temp_5 p {
    font-size: 10px;
  }
  .temp_5 .orange_box h5 {
    font-size: 15px;
  }
  .temp_5 .orange_box ul li {
    font-size: 12px;
  }
}

/* template_6 */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
.template6-font {
  font-family: "Manrope";
}
.temp_6_head1 {
  background: url(./assets/images/template_img/temp6-bg.png) no-repeat center;
  background-size: cover;
  text-align: center;
}
.temp_6_sub {
  padding-top: 150px;
}
.temp_6_head1 h2 {
  font-weight: 800;
  font-size: 52px;
  text-transform: capitalize;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
  padding-bottom: 30px;
}
.temp_6_head1 p {
  font-weight: 500;
  font-size: 16px;
  line-height: 162%;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
  padding-bottom: 39px;
  max-width: 55%;
  margin: auto;
}
.temp_6_head1 button {
  border-radius: 10px;
  padding: 20px 90px;
  background: #ff1edb;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  color: #fff;
  border: 1px solid transparent;
  margin-bottom: 254px;
}
.temp_6_head1 button:hover {
  border: 1px solid #ff1edb;
  background: transparent;
  color: #fff;
}
.temp_6_head2 {
  margin-top: 53px;
}
.temp_6_head2_sub {
  max-width: 84%;
}
.temp_6_head2 h4 {
  font-weight: 600;
  font-size: 40px;
  color: #000;
  margin-bottom: 0;
  padding-bottom: 20px;
}
.temp_6_head2 p {
  font-weight: 500;
  font-size: 14px;
  line-height: 171%;
  color: #282828;
  margin-bottom: 0;
  padding-bottom: 20px;
}
.temp_6_head2 ul li {
  font-weight: 500;
  font-size: 14px;
  line-height: 171%;
  color: #282828;
}
.temp_6_head2 #normal-countdown {
  display: flex;
  gap: 40px;
}
.countdown_temp6 {
  display: flex;
  align-items: center;
  gap: 20px;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border: 1px solid #f0f2f3;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
}
.temp_6_head2_sub2 {
  max-width: 88%;
}
.temp_6_head2 #normal-countdown .time-sec h3 {
  font-weight: 600;
  font-size: 48px;
  color: #3b0085;
  margin-bottom: 0px;
}
.temp_6_head2 #normal-countdown .time-sec p {
  font-weight: 500;
  font-size: 16px;
  color: #515151;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
}
.countdown_temp6 button {
  border-radius: 10px;
  padding: 20px;
  background: #ff1edb;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  color: #fff;
  border: 1px solid transparent;
  white-space: nowrap;
}
.countdown_temp6 button:hover {
  border: 1px solid #ff1edb;
  background: transparent;
  color: #ff1edb;
}
.temp_6_head2_sub2 .profile_details {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border: 1px solid #f0f2f3;
  border-radius: 20px;
  padding: 40px;
  margin-bottom: 20px;
}
.temp_6_head2_sub2 .profile_details h6 {
  margin-bottom: 0;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #000;
}
.temp_6_head2_sub2 .profile_details p {
  margin-bottom: 0;
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  color: #515151;
}
@media (max-width: 1399px) {
  .temp_6_head2_sub2 {
    max-width: 100%;
  }
}
@media (max-width: 1199px) {
  .temp_6_head2_sub {
    max-width: 100%;
  }
  .countdown_temp6 button {
    padding: 15px;
  }
  .temp_6_head2 #normal-countdown .time-sec .main-time {
    font-size: 30px;
  }
  .countdown_temp6 {
    flex-direction: column;
  }
}
@media (max-width: 991px) {
  .countdown_temp6 {
    justify-content: center;
  }
  .temp_6_head1 p {
    max-width: 84%;
  }
}
@media (max-width: 767px) {
  .temp_6_head1 h2 {
    font-size: 37px;
  }
  .temp_6_head1 p {
    font-size: 13px;
  }
  .temp_6_head2 h4 {
    font-size: 31px;
  }
}
@media (max-width: 575px) {
  .temp_6_head1 h2 {
    font-size: 30px;
  }
  .temp_6_head1 p {
    max-width: 100%;
  }
  .countdown_temp6 {
    flex-direction: column;
  }
  .temp_6_head2_sub2 .profile_details {
    flex-direction: column;
    text-align: center;
  }
  .temp_6_head2 #normal-countdown {
    gap: 10px;
  }
}
.template_modal_1 .modal-header {
  border-bottom: none;
}
.template_modal_1 .modal-header .btn-close {
  display: none;
}
.template_modal_1 .modal-title {
  display: flex;
  align-items: center;
  gap: 100px;
}
.template_modal_1 .modal_button_one {
  background: #001f2a;
  border-radius: 100px;
  border: 1px solid #001f2a;
  width: 100%;
  padding: 10px 20px;
}
.template_modal_1 .modal_body_btn {
  display: flex;
  align-items: center;
  gap: 10px;
}
.template_modal_1 .modal_button_one:hover {
  background: #7df9ff;
  color: #001f2a;
  text-decoration: none !important;
  border: 1px solid transparent;
}
.template_modal_1 .modal-title {
  font-weight: 700;
  font-size: 24px;
}
.template_modal_1 .modal-body p {
  font-size: 18px;
  font-weight: 500;
}
.template_modal_2 .modal-header {
  border-bottom: none;
}

.template_modal_2 .modal-body {
  padding-bottom: 10px;
}
.template_modal_2 .modal-header .btn-close {
  padding: 10px;
}
.template_modal_2 .modal-title {
  font-weight: 700;
  font-size: 24px;
}
