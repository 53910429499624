@import url("https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300..900&family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.cdnfonts.com/css/open-sauce-one");
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sauce One", sans-serif;
  font-weight: 400;
  background-color: #ffffff;
  color: #1e1e1e;
  overflow-x: hidden;
}
.form-control::placeholder {
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #696e70 !important;
}

#filterSessions > div {
  box-shadow: 0 !important;
  border: 0 !important;
}
.chart-container {
  height: 230px;
}
.congratsModal .modal-dialog.modal-lg {
  max-width: 650px;
}
.congratsModal .modal-dialog.modal-lg .instant-meeting-head {
  width: 100%;
}
.congratsModal .modal-dialog.modal-lg .completed_meeting {
  width: auto;
}
/* a {
  color: #00ffcc;
  text-decoration: none;
}

a:hover {
  color: #ff437f;
  text-decoration: underline;
} */

/* Menu Toggle */
/* .menu {
  filter: url("#shadowed-goo");
  position: relative;
}
.menu-open-button {
  margin-bottom: 0;
}
.menu-item, .menu-open-button {
  background: #EFF3F7;
  border-radius: 100%;
  width: 34px;
  height: 34px;
  position: absolute;
  top: -17px;
  right: 0;
  color: #11777E;
  text-align: center;
  line-height: 34px;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-open {
  display: none;
}

.hamburger {
  width: 18px;
  height: 2px;
  background: #11777E;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -9px;
  margin-top: 0px;
  transition: transform 200ms;
}

.hamburger-1 {
  transform: translate3d(0, -8px, 0);
}

.hamburger-2 {
  transform: translate3d(0, 0, 0);
}

.hamburger-3 {
  transform: translate3d(0, 7px, 0);
}

.menu-open:checked + .menu-open-button .hamburger-1 {
  transform: translate3d(0, 0, 0) rotate(45deg);
}
.menu-open:checked + .menu-open-button .hamburger-2 {
  transform: translate3d(0, 0, 0) scale(0.1, 1);
}
.menu-open:checked + .menu-open-button .hamburger-3 {
  transform: translate3d(0, 0, 0) rotate(-45deg);
}

.menu {
  box-sizing: border-box;
  text-align: left;
}

.menu-item:hover {
  background: white;
  color: #00bcd4;
}
.menu-item:nth-child(3) {
  transition-duration: 180ms;
}
.menu-item:nth-child(4) {
  transition-duration: 180ms;
}
.menu-item:nth-child(5) {
  transition-duration: 180ms;
}
.menu-item:nth-child(6) {
  transition-duration: 180ms;
}

.menu-open-button {
  z-index: 2;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 400ms;
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  cursor: pointer;
}





.menu-open:checked ~ .menu-item {
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.menu-open:checked ~ .menu-item:nth-child(3) {
  transition-duration: 190ms;
  transform: translate3d(-50px, 0, 0);
}
.menu-open:checked ~ .menu-item:nth-child(4) {
  transition-duration: 290ms;
  transform: translate3d(-100px, 0, 0);
}
.menu-open:checked ~ .menu-item:nth-child(5) {
  transition-duration: 390ms;
  transform: translate3d(-150px, 0, 0);
}
.menu-open:checked ~ .menu-item:nth-child(6) {
  transition-duration: 490ms;
  transform: translate3d(-200px, 0, 0);
} */

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #00ffcc;
}

.main_navbar {
  background: none !important;
}

.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #7df9ff
    url(https://www.vaffix.com/ui-templates/btx-verse-landing/static/media/banner-bg.2e3478a719b8fe3a015d.png)
    no-repeat scroll center top !important;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  z-index: 9;
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.form-control {
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #696e70 !important;
}
.primary_btn {
  background: #7df9ff;
  border: 1px solid transparent;
  border-radius: 50px;
  padding: 8px 12px;
  outline: 0 !important;
  color: #001f2a;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  transition: 0.25s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  gap: 7px;
  min-width: 115px;
  cursor: pointer;
}

.primary_btn img {
  width: 20px;
  height: 20px;
}

.primary_btn img.playIcon {
  width: 17px;
  height: 17px;
}

.primary_btn img {
  transition: 0.25s;
}

.primary_btn:hover {
  background: #001f2a;
  color: #ffffff;
  text-decoration: none !important;
  border: 1px solid transparent;
}

.primary_btn:hover img {
  filter: contrast(0) brightness(0) invert(1);
}

.btn-profile {
  border: 0;
  box-shadow: none !important;
  background: transparent;
  outline: none;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-left: 12px;
}

.btn-profile img {
  border-radius: 50%;
}

.primary_btn2 {
  background: transparent;
  border: 1px solid #001f2a;
  border-radius: 50px;
  padding: 8px 12px;
  outline: 0 !important;
  color: #001f2a;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  transition: 0.25s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  gap: 7px;
  min-width: 115px;
}
.primary_btn3 {
  background: #001f2a;
  color: #fff;
}
.primary_btn3 img {
  filter: contrast(0) brightness(0) invert(1);
}
.primary_btn3:hover {
  background: #7df9ff;
  border: 1px solid #001f2a;
  color: #001f2a;
}
.primary_btn3:hover img {
  filter: contrast(0) brightness(0) invert(0);
}
.primary_btn4 {
  background: #001f2a;
  color: #fff;
}
.primary_btn4 img {
  filter: contrast(0) brightness(0) invert(1);
}
.primary_btn4:hover {
  background: #7df9ff;
  border: 1px solid #001f2a;
  color: #001f2a;
}
.primary_btn4:hover img {
  filter: contrast(0) brightness(0) invert(0);
}
.primary_btn2:hover {
  background: #001f2a;
  color: #ffffff;
  text-decoration: none !important;
  border: 1px solid transparent;
}

.primary_btn2:hover img {
  filter: contrast(0) brightness(0) invert(1);
}

.primary_btn2 svg {
  padding-right: 10px;
  min-width: 18px;
  transition: 0.25s;
}

.primary_btn2 img.playIcon {
  width: 19px;
  height: 19px;
}

.primary_btn2 img.shareIcon {
  width: 16px;
  height: 16px;
}

.white_btn {
  border-radius: 100px;
  backdrop-filter: blur(15px);
  background: rgb(255, 255, 255);
  border: 0;
  border-radius: 50px;
  padding: 10px 20px 10px 20px;
  outline: 0 !important;
  color: rgb(0, 31, 42);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none !important;
}

.navbar_right ul li a {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #394346;
  text-decoration: none;
  transition: 0.3s ease;
  text-transform: capitalize;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  gap: 10px;
}

.navbar_right ul li a:hover,
.navbar_right ul li a.active {
  background: #394346;
  color: #ffffff;
}

.navbar_right ul li a:hover img,
.navbar_right ul li a.active img {
  filter: contrast(0) brightness(0) invert(1);
}

.white_btn:hover {
  color: rgb(0, 31, 42);
  text-decoration: none !important;
}

.input_box input {
  border: 1px solid rgba(4, 83, 247, 0.15) !important;
  outline: 0 !important;
  border-radius: 15px;
  background: #fff;
  padding: 22px 10px;
  box-shadow: none !important;
}

.page_header {
  background: #7df9ff url(../src/assets/images/banner-bg.png) no-repeat scroll
    center center;
  padding: 0px 0 50px;
}

.page_header h1 {
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 30px;
}

.page_header h1 span {
  background: linear-gradient(30deg, #1cc674 20.43%, #0047ff 75.62%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-banner-content {
  max-width: 50%;
  margin: 0 auto 40px;
  text-align: center;
}

.home-banner-content h1 {
  margin: 0 0 30px 0;
  padding: 0;
  font-family: "Darker Grotesque", sans-serif;
  color: rgb(0, 31, 42);
  font-size: 64px;
  line-height: 64px;
  font-weight: 600;
  text-align: center;
}

.home-banner-content p {
  margin-bottom: 30px;
}

section {
  padding: 50px 0;
}
.page-flex {
  display: flex;
}
.page-left {
  width: 210px;
  border-right: 1px solid #dbdfe3;
}
.page-right {
  width: calc(100% - 210px);
  padding: 0 20px;
}
.home-content-secondary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.home-content-secondary small {
  border-radius: 10px;
  background: rgb(240, 242, 243);
  color: rgb(0, 18, 33);
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  padding: 10px 15px 10px 15px;
  margin-bottom: 20px;
  transition: 0.25s;
}

section:hover .home-content-secondary small {
  background: #7df9ff;
}

.home-content-secondary .primary_btn:hover img {
  filter: invert(1);
}

.home-content-secondary h3 {
  margin-bottom: 40px;
  color: rgb(0, 18, 33);
  font-family: "Darker Grotesque", sans-serif;
  font-size: 48px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: -2%;
}

.home-content-secondary p {
  margin-bottom: 40px;
}

.home-grey-two-column {
  background: #dbdfe3;
}

.home-dark-bg {
  background: #001f2a
    url(https://www.vaffix.com/ui-templates/btx-verse-landing/static/media/banner-bg.2e3478a719b8fe3a015d.png)
    no-repeat scroll center top !important;
}

.home-dark-bg .home-content-secondary h3 {
  color: #ffffff;
}

.home-dark-bg .home-content-secondary p {
  color: #ffffff;
  max-width: 70%;
}

.home-dark-bg .home-content-secondary {
  align-items: center;
}

section.home-dark-bg:hover .home-content-secondary small {
  background: #7df9ff;
  color: #001f2a;
}

.ig-card {
  border-radius: 16px;
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 2px 2px 10px 0px rgba(209, 212, 215, 0.5),
    2px 2px 4px 0px rgba(209, 212, 215, 0.5);
  background: rgb(255, 255, 255);
  padding: 40px 24px;
  transition: 0.25s;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ig-card p {
  margin-bottom: 0;
}
.ig-card:hover {
  box-sizing: border-box;
  border: 1px solid rgb(125, 249, 255);
  border-radius: 16px;
  box-shadow: 0px 0px 50px 0px rgba(125, 249, 255, 0.5);
  background: rgba(255, 255, 255, 0.05);
}

.ig-card img {
  margin-bottom: 15px;
}

.industry-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  max-width: 85%;
  justify-content: flex-end;
}

.w-40 {
  width: calc(40% - 30px);
}

.w-60 {
  width: calc(60% - 30px);
}

.w-85 {
  width: 85%;
}

.bg-image-less {
  background-image: none !important;
}

.home-dark-bg .home-content-secondary small {
  background: #0d2a35;
  color: #ffffff;
}

.verticalScroll > div:first-child {
  padding-right: 20px !important;
}

.verticalScroll > div:last-child {
  margin-right: 0px;
  opacity: 1 !important;
  background: #002d3d !important;
  width: 5px !important;
}

.verticalScroll > div:last-child > div {
  background: #7df9ff !important;
}

.vi-card {
  display: flex;
  color: #fff;
  gap: 30px;
  margin: 0px;
  padding: 24px;
  border-bottom: 1px solid #19353f;
  transition: 0.25s;
}

.vi-card:hover {
  background: #0d2a35;
}

.vi-card-icon {
  min-width: 78px;
  width: 78px;
  height: 78px;
  padding: 15px;
  background: #0d2a35;
  border-radius: 10px;
}

.vi-card-text h5 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  transform: 0.25s;
}

.vi-card:hover .vi-card-text h5 {
  color: #7df9ff;
}

.vi-card-text p {
  margin-bottom: 0;
}

.home_slider .slider-container {
  width: 100%;
  margin: auto;

  border: 1px solid #d1d4d7;
  border-radius: 16px 16px 0 30px;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: -1 !important;
}
.slick-prev:hover,
.slick-prev:focus {
  background: url(./assets/images/slider-arrow.svg) no-repeat !important;
  transform: rotate(180deg);
  filter: none !important;
  /* opacity: 10 !important; */
}
.slick-next:hover,
.slick-next:focus {
  background: url(./assets/images/slider-arrow.svg) no-repeat !important;
  filter: none !important;

  /* opacity: 0 !important; */
}
.slick-next:active {
  filter: none !important;
}
.slick-next.active {
  filter: none !important;
}
.home_slider .slick-prev {
  left: unset;
  right: 150px;
  top: -72px;
  background: url(./assets/images/slider-arrow.svg) no-repeat !important;
  transform: rotate(180deg);
  filter: invert(1) grayscale(2) brightness(0.5);
  /* opacity: 0; */
}

.home_slider .slick-prev:before {
  content: "" !important;
  background: #f0f2f3 !important;
  opacity: 0;
}

.home_slider .slick-next {
  left: unset;
  right: 84px;
  top: -64px;
  background: url(./assets/images/slider-arrow.svg) no-repeat !important;
  filter: invert(1) grayscale(2) brightness(0.5);

  /* opacity: 0; */
}

.home_slider .slick-next:before {
  content: "" !important;
  background: #f0f2f3 !important;
  opacity: 0;
}

.slider_h3tag {
  font-weight: 500;
  font-size: 48px;
  font-family: "Darker Grotesque", sans-serif;
  line-height: 100%;
  letter-spacing: -0.02em;
  padding: 30px 30px;
  color: #001f2a;
}

.home_slider .slider_content {
  border-top: 1px solid #d1d4d7;
}

.home_slider .slider_content p {
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 162%;
  color: #fff;
  margin-bottom: 0px;
}

.slider_content .slider_rating {
  background: #e96230 !important;
  background-size: contain;
}

.slider_rating .slider_text {
  padding: 35px 30px;
  border-bottom: 1px solid #eb7144;
}

.profile_rate p {
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  margin-bottom: 10px !important;
}

.profile_rate {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 50px;
}
.home-blog {
  padding: 0 !important;
}
.home-blog-card h3 {
  margin: 30px 0;
  max-width: 75%;
}

.home-blog-card p {
  margin: 0 0px 30px 0;
}

.home-blog-card h3 a {
  color: rgb(0, 31, 42);
  font-size: 27px;
  line-height: 44px;
  font-weight: 400;
  text-decoration: none;
}
.home-section {
  padding-top: 0 !important;
}
.home-trial-banner {
  background: url(../src/assets/images/app-phone-banner-img.png);
  min-height: 388px;
  background-size: cover;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home-trial-banner h3 {
  font-size: 48px;
  font-weight: 500;
  font-family: "Darker Grotesque", sans-serif;
  margin-bottom: 40px;
}

.home-app-download {
  text-align: center;
  padding-bottom: 80px;
  padding-top: 0 !important;
}

.home-app-download h3 {
  font-size: 48px;
  font-family: "Darker Grotesque", sans-serif;
  font-weight: 500;
  line-height: 48px;
  max-width: 60%;
  margin: 0 auto 40px;
}

.home-app-download img {
  filter: grayscale(1);
  margin: 0 12px;
  transition: 0.25s;
}

.home-app-download img:hover {
  filter: grayscale(0);
}

.nav-sidebar {
  height: calc(100vh - 68px);
  border-right: 1px solid #dbdfe3;
  position: sticky;
  top: 68px;
}

.main-footer {
  background: #001f2a;
  padding: 20px 0;
}

.dashboard-page {
  background: #eff3f7;
}

.inner-footer-main {
  background: #eff3f7;
  border-top: 1px solid #dbdfe3;
}

.innerfooter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 20px 0;
}

.innerfooter p {
  font-weight: 400;
  font-size: 13px;
  color: #001f2a;
  margin-bottom: 0;
  padding: 0 20px;
}

.innerfooter p span {
  padding-left: 10px;
  padding-right: 10px;
}

.footer-social {
  display: flex;
  align-items: center;
}

.footer-social span:first-child {
  background: #ffffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.footer-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-card ul {
  margin-bottom: 0;
}

.footer-social a {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 100px;
  backdrop-filter: blur(15px);
  background: transparent;
  padding: 8px 15px;
  display: inline-flex;
  align-items: center;
  margin: 0 7px;
  color: #ffffff;
  text-decoration: none;
}

.footer-social a:hover {
  text-decoration: none;
  background: rgb(13, 42, 53);
}

.footer-card p {
  color: #ffffff;
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}

.footer-logo li {
  margin: 0 10px;
}

.footer-card a {
  color: rgb(255, 255, 255);
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 7px;
  text-decoration: none;
}
.footer-card span {
  color: rgb(255, 255, 255);
}
.footer-card li::marker {
  display: none;
  font-size: 0;
}

.footer-social h5 {
  color: rgb(255, 255, 255);
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 0;
}

/* -----------------------------------------------------------------dashboard-start---------------------------------------------------------------------- */
.sidebar-left {
  height: 100%;
  background-color: #eff3f7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.logo-light {
  padding: 20px 40px;
}

.nav-icons {
  padding-top: 16px;
  border-bottom: 1px solid #dbdfe3;
}

.nav-icons ul {
  list-style-type: none;
  padding-left: 0px;
}

.nav-icons ul li {
  background: transparent;
  transition: 1.3s ease;
  margin: 0 12px 8px;
}

.nav-icons ul li a {
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
  color: #394346;
  text-decoration: none;
  text-transform: capitalize;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.nav-icons ul li a:hover,
.nav-icons ul li a.active {
  background: #394346;
  color: #ffffff;
}

.nav-icons ul li a img {
  padding-right: 12px;
}

.nav-icons ul li a:hover img,
.nav-icons ul li a.active img {
  filter: contrast(0) brightness(0) invert(1);
}

.sidebar-left-footer {
  border-top: 1px solid #dbdfe3;
}

.sidebar-left-footer .nav-icons {
  border-bottom: 0;
}

.sidebar-left-footer ul {
  list-style-type: none;
  padding: 0px 0px 0px 0px;
  margin-bottom: 0px;
}

.sidebar-left-footer ul li img {
  padding-right: 12px;
}

.sidebar-left-footer ul li a {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #394346;
  text-decoration: none;
  transition: 0.3s ease;
}

.sidebar-left-body {
  padding: 20px 40px;
  background: transparent;
  border-left: 1px solid transparent;
  transition: 1.3s ease;
}

.sidebar-left-body:hover {
  background: rgba(255, 255, 255, 0.05);
  border-left: 2px solid #7df9ff;
}

.sidebar-left-body:hover a {
  color: #7df9ff;
}

.sidebar-left-body img {
  padding-right: 30px;
}

.sidebar-left-body a {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  color: #fff;
  text-decoration: none;
}

.dashbord-right {
  padding-top: 24px;
}

.dashbord-right_1 {
  padding-bottom: 30px;
}

.dashbord-right-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dashbord-right-header p {
  font-weight: 500;
  font-size: 16px;
  color: #001f2a;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.titleCount {
  display: inline-flex;
  align-items: center;
  gap: 15px;
}

.countLabel {
  padding: 4px 8px;
  background: rgba(125, 249, 255, 0.25);
  border-radius: 3px;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #11777e;
}

.dashbord-right-body {
  background: #fff;
  border: 1px solid #dbdfe3;
  border-radius: 6px;
  width: 100%;
  /* margin: 0 20px; */
}

.dashbordrightbody-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #dbdfe3;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.dashbordrightbody-header2 {
  flex-direction: column;
  gap: 15px;
}

.dashbordrightbody-header p {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #001f2a;
  margin-bottom: 0px;
}

.dashbordrightbody-header ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0px;
}

.dashbordrightbody-header ul li {
  text-decoration: none;
  position: relative;
  /* border-right: 1px solid #d4d4d4; */
  gap: 30px;
}

.dashbordrightbody-header ul li a {
  padding: 0px 10px;
}

.dashbordrightbody-content {
  display: flex;
  align-items: start;
  gap: 10px;
}

.dashbordrightbody-content p {
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: #394346;
  word-break: break-all;
}

.dashbordrightbody-content p span {
  font-weight: 400;
  color: #7e868d;
}
.dashbordrightbody-content div {
  display: flex;
  gap: 10px;
}
.dashbordrightbody-list {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.dashbordrightbody-list1 {
  padding: 12px;
}
.btn-flex {
  display: flex;
  gap: 10px;
}

/* -----------------------------------------------------------------dashboard-end---------------------------------------------------------------------- */
.nav-bar {
  background: #001f2a;
  padding: 12px 20px;
}

.nav-bar .offcanvas {
  width: 300px !important;
}

.nav-bar .offcanvas-header {
  background: #001f2a;
}

.nav-bar .offcanvas-body {
  background: #001f2a;
  padding: 0px;
}

.sidebar-left1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.navbar-toggler {
  border: 1px solid transparent !important;
}

.navbar-toggler-icon {
  filter: invert(1);
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.btn-close:focus {
  box-shadow: none !important;
}

/* ---------------------------------------------------------navbar-inner-end---------------------------------------------------------------------- */
/* ---------------------------------------------------------popup1-start---------------------------------------------------------------------- */
.model-title {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #001f2a;
}

.btn-close {
  background-image: url(./assets/images/close-btn.png);
}

.modal-content {
  border: 1px solid transparent !important;
  border-radius: 6px;
  padding: 5px 20px 10px !important;
}

.footer-model {
  border-top: 1px solid transparent !important;
  justify-content: center !important;
  gap: 10px;
}

.model-head {
  border-bottom: 1px solid transparent !important;
}

.model-white {
  border: 1px solid rgba(125, 249, 255, 0.5);
  border-radius: 15px;
  padding: 10px 20px 10px 10px;
  margin-bottom: 10px;
  background: #fff;
}

.model-white.active {
  border: 1px solid rgba(125, 249, 255, 0.5);
  box-shadow: 4px 4px 25px 0 rgba(125, 249, 255, 0.35),
    2px 2px 4px 0 rgba(125, 249, 255, 0.5);
}

.model-white.active img {
  background: #e5fdff;
}

.model-white.active .model-content h6 {
  color: #11777e;
}

.model-white:hover {
  border: 1px solid rgba(125, 249, 255, 0.5);
  box-shadow: 4px 4px 25px 0 rgba(125, 249, 255, 0.35),
    2px 2px 4px 0 rgba(125, 249, 255, 0.5);
  cursor: pointer;
}

.model-white:hover img {
  background: #e5fdff;
}

.model-white:hover .model-content h6 {
  color: #11777e;
}

.model-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.model-content img {
  border-radius: 10px;
  padding: 40px;
  border: 1px solid transparent;
}

.model-content h6 {
  font-weight: 600;
  font-size: 20px;
  text-align: start;
  color: #001f2a;
  margin-bottom: 20px;
}
.form-model .modal-body {
  height: 80vh;
}
.model-content ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px;
  gap: 10px;
  list-style-position: inside;
  margin-bottom: 0px;
}

.modal-dialog.modal-lg {
  max-width: 900px;
}

.model-content ul li {
  font-weight: 400;
  font-size: 14px;
  color: #3e545c;
}

.footer-model img.active {
  fill: #7df9ff;
}

/* ---------------------------------------------------------popup1-end---------------------------------------------------------------------- */
/* ---------------------------------------------------------setting-start---------------------------------------------------------------------- */
.white-box {
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  padding: 20px;
  background: #fff;
}

.webinar-setting-content {
  margin-top: 40px;
}

.webinar-setting-content .form-select {
  height: 60px !important;
  border: 1px solid #d1d4d7 !important;
  border-radius: 10px !important;
  padding: 20px !important;
}

.webinar-setting-content .form-control {
  border: 1px solid #dbdfe3;
  border-radius: 3px;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #696e70 !important;
  /* padding: 12px; */
}

.webinar-setting-content .form-label {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #394346;
}

.webinar-setting-content .form-control::placeholder {
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #696e70 !important;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #d1d4d7;
}

.form-select:focus {
  box-shadow: none;
}

.webinar-setting-content .form-select {
  font-weight: 700 !important;
  font-size: 15px !important;
  color: #001f2a !important;
}

.webinar-setting-content p {
  font-weight: 400;
  font-size: 12px;
  color: #7e8385;
  margin-bottom: 0;
  padding-top: 5px;
}

.placeholder_change {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.webinar-setting-content1 {
  margin-top: 40px;
}

.webinar-setting-sub p {
  margin-bottom: 0;
}

.white-box1 {
  border: 1px solid #dbdfe3;
  border-radius: 6px;
  padding: 16px;
  background: #fff;
}

.webinar-setting-sub {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.NewPresenters {
  display: flex;
  align-items: center;
  gap: 20px;
}

.mail-box {
  display: flex;
  align-items: center;
  gap: 20px;
}

.mail-input {
  display: flex;
  gap: 20px;
}

.profile-present {
  display: flex;
  align-items: center;
  gap: 20px;
}

.profile-detail {
  display: flex;
  align-items: center;
  gap: 20px;
}

.profile-detail-align-start {
  align-items: start !important;
}
/* 
.profile-detail-align-start p {
  font-weight: 500;
  font-size: 15px;
  color: #3e545c;
  word-break: break-all;
  margin-bottom: 0;
  padding-bottom: 5px;
  white-space: wrap;
} */

.profile-detail-align-start {
  flex-direction: row !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-start !important;
}

.name-content {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-bottom: 10px;
}

.name-content-1 p {
  font-weight: 700;
  font-size: 20px;
  color: #001f2a;
  white-space: nowrap;
  margin-bottom: 0;
  text-transform: capitalize;
}

.name-content p {
  font-weight: 700;
  font-size: 20px;
  color: #001f2a;
  white-space: nowrap;
  margin-bottom: 0;
}

.verify p {
  font-weight: 700;
  font-size: 12px;
  color: #056507;
}

.email-profile p {
  font-weight: 500;
  font-size: 15px;
  color: #3e545c;
}
.email-profile {
  font-weight: 400;
  font-size: 14px;
  color: #394346;
  word-break: break-all;
}
.name-profile {
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  color: #001f2a;
  margin-bottom: 0;
}
.profile-change {
  display: flex;
  gap: 10px;
  align-items: center;
}

.profile-change img {
  height: 36px;
  width: 36px;
}

.profile-edit {
  background: #eff3f7;
  border-radius: 54px;
  padding: 8px 12px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 31, 42, 0.5);
  text-decoration: none;
  cursor: pointer;
  border: 1px solid transparent;
}
.profile-edit a {
  cursor: pointer;
}
.profile-edit:hover {
  color: #3e545c;
  text-decoration: none;
}

.verify {
  display: flex;
  align-items: center;
}

.webinar-setting-sub-head {
  display: flex;
  gap: 20px;
  align-items: center;
}

.webinar-setting-content1 .form-control::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.webinar-setting-content1 > p {
  padding-bottom: 10px;
}

.webinar-setting-content1-head {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #001f2a !important;
  margin-bottom: 0 !important;
  padding-bottom: 10px !important;
}

.webinar-set {
  display: flex;
  gap: 20px;
}

.mail-input .form-control:focus {
  border: 1px solid #d1d4d7;
}

/* ---------------------------------------------------------setting-end---------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------Webinar-schedule-start------------------------------------------------------ */
.check-head {
  display: flex;
  align-items: center;
}

.time-mangement {
  display: flex;
  gap: 20px;
  cursor: pointer;
}

.cal-img {
  position: relative;
  z-index: 1;
  padding-right: 10px;
  min-width: 24px;
}

.check-head .form-check-label {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #001f2a;
  padding-left: 20px;
}

.time-schedule {
  /* border: 1px solid #d1d4d7; */
  border-radius: 15px;
  /* padding: 20px; */
  cursor: pointer;
}

/* .time-schedule .active {
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border: 1px solid #dbdfe3;
  border-radius: 10px;
  padding: 20px;
} */

/* .time-schedule:hover {
  box-shadow: 4px 4px 4px 0 rgba(125, 249, 255, 0.2),
    0 4px 25px 0 rgba(125, 249, 255, 0.25);
  background: #fff;
  border: 1px solid rgba(125, 249, 255, 0.25);
} */

.time-schedule p {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  color: #3e545c;
  padding-left: 45px;
  padding-bottom: 20px;
}

.check-head .form-check-input {
  height: 25px;
  width: 25px;
}

.check-head .form-check-input:checked[type="radio"] {
  background-image: url(./assets/images/tick.png) !important;
}
.form-check-input:checked[type="radio"] {
  background-image: url(./assets/images/tick.png) !important;
}
.time-event .form-check-input:checked[type="radio"] {
  background-color: transparent;
  border: 1px solid #11777e;
}

.check-head .form-check-input:checked {
  background-color: transparent;
  border: 1px solid #11777e;
}

.form-check-input:focus {
  box-shadow: none;
}

.schedule-details {
  border-radius: 10px;
  padding: 10px;
}

.schedule-details .input-group-text {
  background-color: transparent;
  border: 1px solid transparent;
}

.schedule-details .form-control {
  background-color: transparent;
  border: 1px solid transparent;
}

.schedule-details .input-group {
  border: 1px solid #d1d4d7;
  border-radius: 5px;
  padding: 6px;
  background: #fff !important;
}

.time-schedule h6 {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  padding-top: 20px;
}

.time-event .form-check-input {
  height: 20px;
  width: 20px;
}

.time-event {
  display: flex;
  align-items: center;
}

.time-event .form-check-input:checked[type="radio"] {
  background-image: url(./assets/images/check-circle.png) !important;
}

.time-event .form-check-label {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #7e868d;
  padding-left: 10px;
}

.newSission {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.newSission p {
  font-weight: 600;
  font-size: 14px;
  color: #11777e;
  margin-bottom: 0;
  white-space: wrap;
  border-radius: 10px;
  padding: 10px 20px;
  background: #e5fdff;
  text-align: center;
}

.onbtn {
  border: 1px solid #dbdfe3;
  border-radius: 3px;
  background: #fff;
  padding: 10px 10px;
  font-weight: 700;
  font-size: 14px !important;
  color: #001f2a !important;
  text-decoration: none;
}

.onbtn:hover {
  color: #001f2a;
  text-decoration: none;
}

.newSission .input-group-text {
  border: 1px solid transparent;
  background: transparent;
  padding: 0;
  padding-right: 20px;
  position: relative;
}

/* .newSission .input-group-text img{
  width: 100%;
  max-width: 42px;
} */
.newSission .react-datepicker__input-container input {
  border: 1px solid transparent;
  background: transparent;
  position: absolute;
  top: -19px;
  left: -46px;
  padding-left: 52px;
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.newSission .input-group {
  border: 1px solid #dbdfe3;
  background: #fff;
  border-radius: 3px;
  padding: 10px 15px;
  align-items: center;
  position: relative;
  flex-wrap: nowrap;
}

.newSission_1 .input-group {
  padding: 8px !important;
  position: relative;
}

.newSission_1 .img-position {
  position: absolute;
  z-index: 1;
}

.date_time {
  padding-left: 30px;
  background: #fafafa;
}

.date_time .form-control {
  background: #fafafa;
}

.newSission .form-control {
  border: 1px solid transparent;
  padding: 0px;
  height: auto !important;
  background: #fff;
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.newSission .form-select {
  border: 1px solid #dbdfe3;
  border-radius: 3px;
  padding: 10px 15px;
  padding-right: 35px;
  background: #ffff;
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.newsission-addbtn {
  border-radius: 10px;
  padding: 10px 12px;
  background: #7df9ff;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #001f2a;
  text-decoration: none;
}

.newsission-addbtn2 {
  border-radius: 10px;
  padding: 10px 15px;
  background: #dbdfe3;
  text-decoration: none;
}

.newsission-addbtn:hover {
  color: #11777e;
  text-decoration: none;
}

.newSission-bg {
  background: #fafafa;
  padding: 20px;
  margin-top: 20px;
}

.timezoneconversion-head {
  display: flex;
  align-items: start;
  padding-top: 20px;
}

.timezoneconversion h6 {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
}

.timezoneconversion p {
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  color: #3e545c;
}

.timezoneconversion-head .form-check-input:checked {
  background-color: #11777e;
}

.timezoneconversion-head .form-switch .form-check-input {
  border: 1px solid #11777e;
}

/* -----------------------------------------------------------------------------------Webinar-schedule-end------------------------------------------------------ */

/* ---------------------------------------------------------Accordion-start--------------------------------------------------------------------- */
.accMain .accordion-item {
  margin-bottom: 20px !important;
  border: 1px solid transparent !important;
  background: #fff !important;
  border-radius: 6px !important;
}

.accMain .accordion-button:focus {
  box-shadow: none;
}
.accMain .accordion-collapse:not(.collapsed) {
  border: 1px solid #bdc1c5 !important; /* Change color as needed */
  border-top: 1px solid transparent !important;
  border-radius: 6px !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.accMain .accordion-button:not(.collapsed) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #dbdfe3;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  background: transparent;
}
.accordion-button:not(.collapsed) {
  border: 1px solid #bdc1c5 !important; /* Change color as needed */
  border-bottom: 1px solid #dbdfe3 !important;
  border-radius: 6px !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.accMain .accordion-button {
  border: 1px solid #bdc1c5 !important;
  border-radius: 6px !important;
  padding: 17px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: none !important;
}

.accMain .accordion-button::after {
  display: none;
}

.accMain .accordion-header-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.accMain .accordion-button p {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #001f2a;
  margin-bottom: 0px;
}

.accordion-header-content p {
  font-weight: 700;
  font-size: 16px;
  color: #28b42b;
  margin-bottom: 0px;
}

.accordionedit-btn {
  border-radius: 10px;
  padding: 5px 10px;
  background: #11777e;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  border: 1px solid transparent;
}

/* ---------------------------------------------------------Accordion-end---------------------------------------------------------------------- */

/* --------------------------------------------------instant-meeting-start----------------------------------------- */

.instant-meeting-head {
  width: 60%;
  margin: auto;
}

.instant-meeting .white-box {
  padding: 40px !important;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 50px;
}

.instant-meeting-box .border_box {
  align-items: center !important;
}

.instant-meeting-box .border_box h5 {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #001f2a;
  margin-top: 20px;
  text-transform: capitalize;
}

.instant-meeting-box .white-box h6 {
  font-weight: 700;
  font-size: 20px;
  color: #001f2a;
  margin-bottom: 0;
  padding-top: 20px;
  white-space: nowrap;
}

.instant-meeting-box .border_box .profile {
  text-align: center;
}

.profile-img-width {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  text-align: center;
}

.invite-temp {
  border: 1px solid #d1d4d7;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
}

.invite-temp p {
  font-weight: 500;
  font-size: 16px;
  color: #3e545c;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.invite-temp h6 {
  font-weight: 700 !important;
  font-size: 24px !important;
  color: #11777e !important;
  margin-bottom: 0 !important;
  padding-bottom: 10px !important;
  padding-top: 0px !important;
  white-space: wrap !important;
}

.record_btn {
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 10px;
  padding: 10px 20px 10px 10px;
  background: #e5fdff;
}

.record_btn p {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  text-decoration: none !important;
  font-weight: 600;
  font-size: 15px;
  color: #11777e;
}

.invite_details_head {
  padding-top: 40px;
}

.invite_details {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
}

.invite_details .text-box {
  width: 50%;
}

.invite_details .text-box label {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #001f2a;
}

.invite_details .text-box input {
  border: 1px solid #d1d4d7;
  border-radius: 6px;
}

.invite_details .text-box input::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #3e545c;
}

.invite_details .text-box .form-control:focus {
  border: 1px solid #d1d4d7;
}

.invite_details_head .form-check-input:checked {
  background-color: #001f2a !important;
  border-color: #001f2a;
}

.invite_details_head .instant-btn {
  text-align: center;
  padding-top: 40px;
}

.invite_details_head .instant-login {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.invite_details_head .instant-login a {
  font-weight: 500;
  font-size: 16px;
  color: #3e545c;
}

.invite_details_head .instant-login a span {
  font-weight: 700;
  color: #001f2a;
}

.completed_meeting {
  max-width: 600px;
  margin: 0 auto;
}

/* --------------------------------------------------instant-meeting-end----------------------------------------- */
/* --------------------------------------------------LastMinuteChecklist1-start---------------------------------------- */
.LastMinuteChecklist1-box h5 {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #001f2a;
  margin-bottom: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdfe3;
}

.LastMinuteChecklist1 .white-box {
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}

.LastMinuteChecklist1-box {
  width: 80%;
  margin: auto;
}

.LastMinuteChecklist1-box .head-def .box1 {
  border: 1px solid #e7e7e7;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}

.LastMinuteChecklist1-box .head-def {
  padding-top: 20px;
}

.LastMinuteChecklist1-box .head-def .box1 .def1 {
  display: flex;
  align-items: center;
  gap: 20px;
}

.LastMinuteChecklist1-box .head-def .box1 .def1 h6 {
  font-weight: 600;
  font-size: 20px;
  color: #001f2a;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.LastMinuteChecklist1-box .head-def .box1 .def1 p {
  font-weight: 500;
  font-size: 16px;
  line-height: 162%;
  color: #3e545c;
  margin-bottom: 0;
}

.LastMinuteChecklist1-btn {
  text-align: center;
  margin: 20px;
  margin-bottom: 0;
}

/* --------------------------------------------------LastMinuteChecklist1-end---------------------------------------- */
/* --------------------------------------------------LastMinuteChecklist2-start---------------------------------------- */
.LastMinuteChecklist2-box {
  width: 80%;
  margin: auto;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}

.LastMinuteChecklist2-box h5 {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #001f2a;
  margin-bottom: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdfe3;
}

.LastMinuteChecklist2-box .head-def {
  display: flex;
  align-items: center;
  gap: 40px;
  padding-top: 20px;
}

.LastMinuteChecklist2-box .head-def h6 {
  font-weight: 600;
  font-size: 20px;
  color: #001f2a;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.LastMinuteChecklist2-box .head-def p {
  font-weight: 500;
  font-size: 16px;
  line-height: 162%;
  color: #3e545c;
  margin-bottom: 0;
}

/* --------------------------------------------------LastMinuteChecklist2-end---------------------------------------- */
/* --------------------------------------------------LastMinuteChecklist2-start---------------------------------------- */
.LastMinuteChecklist3-box {
  width: 80%;
  margin: auto;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}

.LastMinuteChecklist3-box .box1 {
  width: 50%;
}

.LastMinuteChecklist3-box .box1 .vdo_img {
  position: relative;
}

.LastMinuteChecklist3-box .box1 .video_conf {
  display: flex;
  align-items: center;
  position: absolute;
  gap: 20px;
  margin-top: -60px;
  padding-left: 20px;
}

.LastMinuteChecklist3-box .box1 .video_conf p {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
  text-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.75);
}

.LastMinuteChecklist3-box .head-def {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
}

.LastMinuteChecklist3-box {
  width: 80%;
  margin: auto;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
}

.LastMinuteChecklist3-box h5 {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #001f2a;
  margin-bottom: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdfe3;
}

.LastMinuteChecklist3-box .head-def .box1 .form-label {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #001f2a;
}

.LastMinuteChecklist3-box .head-def .box1 .form-control {
  border: 1px solid #d1d4d7 !important;
  border-radius: 10px !important;
  margin-bottom: 20px;
}

.LastMinuteChecklist3-box .head-def .box1 .form-control:focus {
  box-shadow: none;
  border: 1px solid #d1d4d7;
}

.LastMinuteChecklist3-box .head-def .box1 .form-control::placeholder {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
}

.LastMinuteChecklist3-box P {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
}

.LastMinuteChecklist3-box .btn_1 {
  border: 1px solid #d80027;
  border-radius: 100px;
  padding: 5px 40px;
  background: #fff;
  font-weight: 600;
  font-size: 16px;
  color: #d80027;
  margin-right: 20px;
}

.LastMinuteChecklist3-box .btn_1:active {
  background: #d80027;
  color: #fff;
}

.LastMinuteChecklist3-box .btn_2 {
  border: 1px solid #28b42b;
  border-radius: 100px;
  padding: 5px 40px;
  background: #fff;
  font-weight: 600;
  font-size: 16px;
  color: #28b42b;
}

.LastMinuteChecklist3-box .btn_2:active {
  background: #28b42b;
  color: #fff;
}

/* --------------------------------------------------LastMinuteChecklist3-end---------------------------------------- */

/* --------------------------------------------------------Email-Notifications-start-------------------------------------------- */
.emailnotifications .modal-content {
  padding: 0px !important;
}

.emailnotifications .model-head {
  background-color: #effeff;
  border-radius: 30px 30px 0 0;
  padding: 20px;
}

.emailnotifications .model-head {
  justify-content: space-between;
}

.mailnotify-body p {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  align-items: center;
  display: flex;
  margin-bottom: 0px;
  padding-top: 60px;
}

.mailnotify-body p span {
  border-radius: 10px;
  padding: 10px;
  background: #effeff;
  font-weight: 700;
  font-size: 16px;
  color: #11777e;
  margin: 0px 10px;
}

.mail-head .form-label {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #001f2a;
  padding-top: 40px;
}

.mail-head .form-control {
  border: 1px solid #d1d4d7;
  border-radius: 10px;
  padding: 15px;
}

.shortcodes {
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border: 1px solid #dbdfe3;
  border-radius: 20px;
  padding: 20px;
  height: 385px;
  overflow: auto;
}

.shortcodes h6 {
  border-radius: 5px;
  padding: 20px;
  background: #effeff;
  font-weight: 500;
  font-size: 15px;
  color: #3e545c;
}

.shortcodes h6 span {
  font-weight: 700;
  font-size: 16px;
  color: #11777e;
}

.btn-finish {
  text-align: center;
  padding-top: 50px;
}

/* --------------------------------------------------------Email-Notifications-end-------------------------------------------- */
/* --------------------------------------------------------Configuration-registration-start-------------------------------------------- */
.inner-accMain .input-box-1 .form-control {
  border: 1px solid transparent;
  font-weight: 400;
  font-size: 13px;
  color: rgba(105, 110, 112, 0.75);
  background: #eff3f7;
}

.inner-accMain .input-box-1 .btn:active {
  background: transparent !important;
  border: 1px solid transparent;
}

.inner-accMain p {
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
  color: #394346;
  margin-bottom: 10px;
}

.inner-accMain .input-box-1 .btn {
  border: 1px solid transparent;
  background: transparent;
  color: red;
}

.inner-accMain .input-box-1 {
  border-radius: 3px;
  background: #eff3f7;
  align-items: center !important;
}

.inner-accMain .input-box-1.active {
  background: #d6ffff !important;
  border: 1px solid transparent !important;
}

.inner-accMain .input-box-1.active .form-control {
  background: #d6ffff !important;
  border: 1px solid transparent !important;
}

.inner-accMain .Add_fields {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Add_fields .form-control:disabled {
  background: #fff !important;
  cursor: not-allowed;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

textarea {
  resize: none;
}

.inner-accMain .Add_fields_2 {
  display: flex;
  align-items: center;
  gap: 20px;
  background: #7df9ff40;
  border-radius: 10px;
  padding: 10px;
}

.inner-accMain .Add_fields_2 p {
  margin-bottom: 0;
}

.inner-accMain .Mandatory_flelds {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.inner-accMain .Mandatory_flelds p {
  font-weight: 400;
  font-size: 13px;
  color: #11777e;
  margin-bottom: 0;
}

.inner-accMain .Add_fields_2_content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.inner-accMain .Add_fields_2_content p {
  font-weight: 400;
  font-size: 13px;
  color: #394346;
  margin-bottom: 0;
}
.Mandatory_flelds_head {
  font-weight: 500 !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
  color: #394346 !important;
}
.inner-accMain .Add_fields_2_content.active p {
  color: #11777e !important;
}

.inner-accMain .Add_fields_2_content.active img {
  filter: saturate(8) hue-rotate(-19deg) brightness(0.7) !important;
}

.inner-accMain .White_box_1 {
  border-radius: 3px;
  padding: 16px;
  background: #f4f8fc;
}

.inner-accMain .input-box-1 .input-group-text {
  border: 1px solid transparent;
  background: transparent;
}

.inner-accMain .input-box-1 .form-control::placeholder {
  font-weight: 400;
  font-size: 13px;
  color: rgba(105, 110, 112, 0.75);
}

.inner-accMain .White_box_1 .Add_fields .input-box-1 {
  backdrop-filter: blur(75px);
  background: #fff;
  border: 1px solid #dbdfe3;
  border-radius: 3px;
  padding: 5px;
}

.tab_content_11 > div:first-child {
  width: 78%;
}

.nav_pills1 {
  width: 70%;
}

.nav_link1 {
  width: 47%;
}

.inner-accMain .nav-pills .nav-link {
  padding: 0;
}

.inner-accMain .nav-pills .nav-link .form-check label h6 {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #394346 !important;
}

.inner-accMain .nav-pills .nav-link .form-check label p {
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  color: #696e70;
  margin-bottom: 0;
}

.inner-accMain .nav-pills .nav-link.active,
.inner-accMain .nav-pills .show > .nav-link {
  background: #fff;
  border-radius: 10px;
  padding: 0px;
}

.inner-accMain .nav-pills .nav-link.active h6 {
  color: #11777e !important;
}

.inner-accMain .form-check-input:checked {
  background-color: #29cf3a;
  border-color: #29cf3a;
}

/* .tab_content_1_sub .form-check-input:checked ~ .tab_content_1_sub {
  background: red; /* Change background color of .tab_content_1_sub when checkbox is checked
}
.tab_11 .form-check-input:checked {
  background: red; /* Change background color of .tab_content_1_sub when checkbox is checked
}
.tab_11 .form-check-input:checked .form-check {
  background-color: red; /* Change color of <h6> when radio button is checked 
}
.form-check .form-check-input:checked {
  background: red !important;
} */
.inner-accMain .nav_pills_content {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.inner-accMain .nav_pills_content .form-control {
  border: 1px solid #c5e1e2;
  border-radius: 5px;
  background: transparent;
}

.inner-accMain .nav_pills_content .form-control::placeholder {
  font-weight: 600;
  font-size: 14px;
  color: #001f2a;
}

.nav_pills_content_head {
  background: #effeff;
  border: 1px solid #c5e1e2;
  border-radius: 10px;
  padding: 10px;
}

.nav_pills_content_head .form-check-label {
  font-weight: 700;
  font-size: 14px;
  color: #11777e;
}

.nav_pills_content_head .form-check {
  margin-bottom: 10px;
}

.nav_pills_content_head .btn {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  background: #7df9ff;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid transparent;
}

.img-bg {
  border: 1px solid #c5e1e2;
  border-radius: 6px;
  padding: 0 10px;
  background: #effeff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-bg-1 {
  width: 20%;
}

.notify-img_width {
  width: 30%;
}

.tab_content_11 {
  display: flex;
  gap: 20px;
}

.img-width {
  width: 30%;
}

.tab_content_1_sub {
  width: 50%;
}

.tab_content_1_sub2 {
  width: 50%;
}
.tab_flex p {
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 154% !important;
  color: #696e70 !important;
}
.flex-tab-11 p {
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 154% !important;
  color: #696e70 !important;
}
.select_box_1 {
  background: #dbdfe3;
  border-radius: 0 0 10px 10px;
  padding: 20px;
  margin-top: 20px;
}

.select_box_1 label {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #001f2a;
}

.select_box_1 .form-select {
  width: 30%;
  background: #fafafa;
  font-weight: 400;
  font-size: 13px;
  color: #696e70;
}
.select_box_1 .form-select::placeholder {
  font-weight: 400;
  font-size: 13px;
  color: #696e70;
}
.tab_content_1_sub3_head {
  width: 30%;
  border: 1px solid #c5e1e2;
  border-radius: 10px;
  padding: 10px;
  background: #effeff;
  margin: auto;
}

.tab_content_1_sub3_head .btn {
  border-radius: 5px;
  padding: 10px;
  background: #7df9ff;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  margin-top: 10px;
  border: 1px solid transparent;
}

.tab_content_1_sub3 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tab-content-12 {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.tab-content-12 .form-control {
  width: 40%;
}

.tab-content-12-head {
  background: #dbdfe3;
  border-radius: 0 0 10px 10px;
  padding: 20px;
  margin-top: 20px;
  min-height: 250px;
}

.tab-content-12-head .whitebox-content {
  border-radius: 10px;
  padding: 20px;
  background: #fff;
  margin-top: 20px;
}

.tab-content-12-head .whitebox-content .form-control {
  width: 30%;
}

.inner-accMain .accordion-button {
  font-weight: 700;
  font-size: 16px;
  color: #001f2a;
}

.tab-content-12-head .whitebox-content label {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #001f2a;
}

.tab-content-12-head .whitebox-content .form-control {
  border: 1px solid #d1d4d7;
  border-radius: 10px;
}

.tab-content-12-head .whitebox-content .form-control::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.btn:focus {
  box-shadow: none !important;
}

/* --------------------------------------------------------Configuration-registration-end-------------------------------------------- */
/* --------------------------------------------------------Configuration-notification-start-------------------------------------------- */
.notify {
  display: flex;
}

.notify_sub {
  display: flex;
}

.notify_sub .form-check1 {
  width: 48%;
}

.notify .hide_content {
  width: 48%;
}

.notify .form-check1 {
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 20px;
}

.notify .form-check1.checked {
  border: 1px solid #dbdfe3;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}

.notify .checked-label h6 {
  color: #11777e;
  /* Change the color of h6 when checked */
}

.notify .form-check label p {
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  color: #3e545c;
}

.notify .form-check label h6 {
  font-weight: 600;
  font-size: 16px;

  color: #001f2a;
}

/* --------------------------------------------------------Configuration-notification-end-------------------------------------------- */

@media (max-width: 1399px) {
  .home-blog-card h3 {
    max-width: 88%;
  }
  .main-footer .container {
    max-width: 100%;
  }
  .inner_fields1 .img_position_basic .image_edit {
    left: 80px;
  }
  .grid_Row_list .grid_Row_list_child {
    flex-wrap: wrap;
  }
  /* .filterSessions {
    width: 36% !important;
  }
  .grid_Row_list .input-group {
    width: 32% !important;
  } */
  .grid_Row_list_child .registration_nav_content {
    width: 41px !important;
  }
}

@media (max-width: 1199px) {
  .inner_fields1 .img_position_basic .image_edit {
    left: 71px;
  }
  .industry-grid {
    max-width: 100%;
    margin-top: 12px;
  }

  .home-banner-content {
    max-width: 100%;
  }

  .home-content-secondary {
    align-items: self-start;
    margin-bottom: 40px;
    height: 100%;
    text-align: start;
  }

  .content-image {
    text-align: center;
  }

  .home-content-secondary p {
    text-align: start;
  }

  .industry-grid {
    justify-content: center;
  }

  .home-trial-banner {
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
  }

  .footer-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }

  .footer-card ul {
    display: inline-flex;
  }

  .home-app-download h3,
  .home-trial-banner h3,
  .home-content-secondary h3 {
    font-size: 40px;
  }

  .home-blog-card h3 a {
    font-size: 20px;
    line-height: 24px;
  }

  .w-85 {
    width: 100%;
  }

  /* .ig-card.w-60,
  .ig-card.w-40 {
    width: calc(33% - 30px);
  } */

  /* .footer-social {
    margin-top: 24px;
  } */

  .home-dark-bg .home-content-secondary p {
    max-width: 100%;
  }

  .model-content ul li {
    font-size: 12px;
  }

  .mail-input {
    flex-direction: column;
  }

  .profile-present {
    flex-direction: column;
  }

  .webinar-setting-sub {
    min-height: 350px !important;
  }

  .webinar-setting-content p {
    font-size: 12px;
  }

  .webinar-setting-content .form-label {
    font-size: 14px;
    white-space: nowrap;
  }

  .instant-meeting-head {
    width: 90%;
  }

  .inner-accMain .input-box-1 .btn {
    padding: 0;
  }

  .inner-accMain .input-box-1 .input-group-text {
    padding: 0;
  }

  .Auto_subscription {
    flex-direction: column;
    gap: 20px;
  }

  .nav_pills1 {
    width: 100%;
  }

  .Auto_subscription .tab-content {
    margin: auto;
  }
}
/* @media (max-width: 993px) {
  
} */
@media (max-width: 991px) {
  .home-app-download h3,
  .home-trial-banner h3,
  .home-content-secondary h3 {
    font-size: 40px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    margin-bottom: 20px;
  }
  .home-app-download h3 {
    max-width: 80%;
  }
  .home_page section {
    padding: 25px 0;
  }
  .home-content-secondary {
    margin-bottom: 0;
    align-items: center;
  }
  .home-content-secondary p {
    text-align: center;
  }
  .mobile-reverse {
    flex-direction: column-reverse;
  }
  /* .home-blog-card h3 {
    margin: 30px auto;
  } */
  .home-blog-sub {
    margin-top: 10px;
  }
  .page-right {
    width: 100%;
    padding: 0;
  }
  .page-left {
    border: 1px solid transparent;
    width: auto;
  }
  .inner_fields1 .img_position_basic .image_edit {
    left: 50px;
    right: 0;
    margin: auto;
  }
  .nav-sidebar {
    display: none;
  }
  .container {
    max-width: 96%;
  }

  /* .vi-content-right {
    margin-top: 40px;
  } */

  .sidebar-left-header .navbar-brand {
    display: none;
  }

  .model-content h6 {
    font-size: 15px;
  }

  .model-content ul {
    flex-direction: column;
  }

  .profile-present {
    flex-direction: row;
  }

  .profile-detail {
    flex-direction: row !important;
  }

  .webinar-setting-sub {
    min-height: 0 !important;
  }

  .mail-input {
    flex-direction: row !important;
  }

  .accItem {
    margin-top: 30px;
  }

  .LastMinuteChecklist2-box .head-def {
    flex-direction: column;
  }

  .LastMinuteChecklist3-box .head-def {
    flex-direction: column-reverse;
  }

  .LastMinuteChecklist3-box .box1 {
    width: 100%;
  }

  .LastMinuteChecklist3-box .box1 .vdo_img {
    display: block;
    margin: auto;
  }

  .LastMinuteChecklist3-box .box1 .video_conf {
    left: 0;
    right: 0;
    justify-content: center;
  }

  .dashbord-right {
    margin-left: 0;
    width: 100%;
  }

  .settings_shadow {
    margin-left: 0 !important;
  }

  .settings_shadow {
    margin: 118px 15px 0px !important;
  }
  .ig-card.w-60,
  .ig-card.w-40 {
    width: calc(100% - 70%);
  }
}

@media (min-width: 991px) {
  .nav-sidebar2 {
    display: none;
  }
}

@media (max-width: 767px) {
  .home-blog-card h3 {
    margin: 20px auto 10px;
  }
  .home-banner-content h1 {
    font-size: 51px;
    line-height: 47px;
  }
  .blue_flex_sub div {
    gap: 10px !important;
  }
  .dashbord-right-header div.mobFullwidth {
    width: 100%;
  }
  .registrants-page .dashbord-right-header {
    flex-direction: column;
  }
  .registrants-page .datapick_head {
    width: 100%;
    min-width: 100% !important;
  }
  .registrants-page .react-datepicker-wrapper {
    width: 100%;
  }
  .registrants-page .datapick {
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }
  .btn-flex {
    width: 100%;
    justify-content: center;
  }
  .inner_fields1 .img_position_basic .image-dp {
    margin: auto;
  }
  .home-blog-card {
    text-align: center;
    margin-bottom: 50px;
  }
  .home-blog-card:last-child {
    margin-bottom: 0px;
  }
  .ig-card.w-60,
  .ig-card.w-40 {
    width: calc(50% - 30px);
  }

  .home-app-download h3 {
    max-width: 100%;
  }

  .home-app-download img {
    max-width: calc(33% - 24px);
  }

  .footer-card {
    flex-direction: column;
    gap: 20px;
  }

  .footer-card p {
    text-align: center;
    /* margin-top: 15px;
    width: 100%; */
  }

  .footer-social {
    justify-content: center;
    width: 100%;
  }

  .instant-meeting-box .white-box {
    flex-direction: column;
  }

  .notify {
    flex-direction: column;
  }

  .hide_content {
    margin: auto;
  }

  .inner_settings_alignment {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .img_position_basic {
    justify-content: flex-start !important;
    margin-bottom: 10px !important;
  }

  .inner_fields {
    flex-wrap: wrap;
  }

  .inner_profile_settings {
    width: 100%;
  }

  .grid_Row_list {
    flex-direction: column;
    gap: 30px;
    align-items: center !important;
  }

  .grid_Row_list .grid_Row_list_child {
    /* flex-direction: column; */
    align-items: flex-start !important;
    gap: 30px;
    width: 100%;
  }

  .grid_Row_list .input-group {
    max-width: 100%;
  }

  .grid_Row_list_child .input-group:focus-visible {
    border: 1px solid #001f2a;
  }

  .filterSessions {
    max-width: 100%;
  }
  .invite_details {
    flex-direction: column;
  }
  .invite_details .text-box {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .home-banner-content h1 {
    font-size: 31px;
    line-height: 33px;
    margin-bottom: 15px;
  }
  .home-app-download h3,
  .home-trial-banner h3,
  .home-content-secondary h3 {
    font-size: 27px;
    line-height: 28px;
  }
  .inner_profile_settings h5 {
    text-align: start;
  }
  .inner_profile_settings button {
    float: left;
    margin-bottom: 20px !important;
  }
  .reset_code h2 {
    text-align: start;
  }
  /* .ig-card.w-60,
  .ig-card.w-40 {
    width: calc(100% - 30px);
  } */
  .ig-card {
    padding: 15px;
  }
  .ig-card p {
    font-size: 12px;
  }
  .vi-card-text {
    text-align: center;
  }

  .footer-social {
    flex-wrap: wrap;
  }

  /* .footer-social a {
    margin-bottom: 12px;
  } */

  .footer-card {
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }

  .footer-logo {
    margin-bottom: 0;
  }
  .footer-card a {
    padding: 0;
  }
  .footer-social span:first-child {
    margin-right: 0;
  }
  .footer-social span:nth-child(2) {
    display: none;
  }
  .download-button-group {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-app-download a {
    margin-bottom: 12px;
  }

  .home-app-download img {
    max-width: 144px;
  }

  .dashbordrightbody-list {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .dashbordrightbody-header ul li a {
    padding: 0px 15px;
  }

  .model-content {
    flex-direction: column;
  }

  .model-content {
    flex-direction: column;
  }

  .emailnotifications .model-head {
    flex-direction: column;
    gap: 20px;
  }

  .webinar-setting-sub {
    min-height: 200px !important;
  }

  .profile-detail {
    flex-direction: column !important;
  }

  .mail-input {
    flex-direction: row !important;
  }

  .profile-present {
    flex-direction: column;
  }

  .NewPresenters {
    flex-direction: column;
  }

  .mail-box {
    flex-direction: column;
  }

  .accMain .accordion-button p {
    font-size: 20px;
  }

  .accMain .accordion-button {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .webinar-setting-content .form-label {
    font-size: 14px;
  }

  .instant-meeting-head {
    width: 100%;
  }

  .invite-temp p {
    font-size: 11px;
  }

  .invite-temp h6 {
    font-size: 19px !important;
  }

  .record_btn p {
    font-size: 13px;
  }

  .invite-temp {
    padding: 15px;
    margin-top: 20px;
  }

  .invite_details .text-box {
    width: 100%;
  }

  .invite_details {
    flex-direction: column;
  }

  .invite_details .form-check-label {
    font-size: 13px;
  }

  .invite_details_head .instant-login a {
    font-size: 14px;
  }

  .LastMinuteChecklist2-box {
    width: 100%;
  }

  .LastMinuteChecklist2-box h5 {
    font-size: 21px;
  }

  .LastMinuteChecklist2-box .head-def h6 {
    font-size: 19px;
  }

  .LastMinuteChecklist2-box .head-def p {
    font-size: 15px;
  }

  .LastMinuteChecklist3-box {
    width: 100%;
  }

  .LastMinuteChecklist3-box h5 {
    font-size: 21px;
  }

  .LastMinuteChecklist3-box .box1 .video_conf p {
    font-size: 17px;
  }

  .LastMinuteChecklist3-box .box1 .video_conf {
    gap: 10px;
    padding-left: 0;
  }

  .inner-accMain .Add_fields {
    flex-wrap: wrap;
  }

  .inner-accMain .Mandatory_flelds {
    flex-direction: column;
    gap: 10px;
  }

  .inner-accMain .Add_fields_2 {
    flex-wrap: wrap;
    row-gap: 6px;
  }

  .inner-accMain .nav-pills .nav-link .form-check label p {
    display: none;
  }

  .inner-accMain .nav-pills .nav-link .form-check label h6 {
    font-size: 16px !important;
  }

  .nav_link1 {
    width: 100%;
  }

  .registration_sec_image img {
    object-fit: contain;
  }

  .notify .form-check label p {
    display: none;
  }

  .notify .form-check label h6 {
    font-size: 11px;
  }

  .tab_3_content {
    flex-direction: column;
    gap: 30px !important;
  }

  .likes_event {
    top: 43px;
  }

  .dashbord-right-body_Analytics1 .dashbordrightbody-header {
    flex-direction: column;
    gap: 10px;
  }

  /* .inner_settings_details .primary_btn {
    padding: 2px 5px !important;
    font-size: 14px !important;
  } */

  .inner_settings_details p {
    font-size: 13px !important;
  }

  .inner_settings_details span {
    font-size: 12px !important;
  }

  .inner_settings_details {
    gap: 5px !important;
    padding: 8px !important;
  }

  .inner_settings_shadow {
    padding: 15px !important;
  }

  /* .settings_display .primary_btn {
    width: 100% !important;
  } */

  .temp_config {
    justify-content: center;
  }

  .temp-head {
    flex-direction: column-reverse;
    gap: 20px;
  }
}

@media (max-width: 1699px) {
  .profile-detail {
    flex-direction: column;
  }

  .webinar-setting-sub {
    min-height: 250px;
  }

  .newSission .react-datepicker__input-container input {
    padding-left: 40px;
  }
}

/* login_page */
.nav_sec {
  background-color: #ffffff;
  border-bottom: 1px solid #e8ecf0;
  position: sticky;
  top: 0;
  z-index: 99;
}

.nav_sec .navbar-brand {
  max-width: 108px;
}

.login_page {
  background-image: url(./assets/images/login_img/bg_img.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #7df8fe;
  opacity: 5.5;
  min-height: calc(100vh - 157px);
  display: flex;
  align-items: center;
}

.login_h3tag {
  color: #001f2a;
  text-align: center;
}

.login_content {
  margin: auto;
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 20px;
  width: 30%;
  background-color: #fff;
}

.login_content h4 {
  font-weight: 600;
  font-size: 32px;
  line-height: 81%;
  letter-spacing: -0.02em;
  text-align: center;
  color: #001f2a;
}

.login_content .form-label {
  font-weight: 500;
  font-size: 15px;
  color: #001f2a;
  margin-bottom: 8px;
}

.login_content .form-control {
  border: 1px solid transparent !important;
  background-color: #dbdfe3;
  border-radius: 3px;
  padding: 12px 10px;
  color: #001f2a;
  font-weight: 500;
}

.login_content .form-control:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid transparent !important;
}

.login_content .input-group {
  border: 1px solid transparent !important;
  background-color: #dbdfe3;
  border-radius: 3px;
}

.login_content .input-group-text {
  border: 1px solid transparent !important;
  background-color: #dbdfe3;
}

.eye_icon {
  cursor: pointer;
}

.login_content p {
  margin-bottom: 0px;
  text-align: right;
}

.login_content p a {
  text-decoration: none;
  font-weight: 600;
  font-size: 15px;
  color: #001f2a;
}

.login_content p a:hover {
  color: #0fcdd6;
  transition: 0.5 !important;
}

.login_button {
  border-radius: 100px;
  padding: 10px 20px;
  background: #7df9ff;
  border: 1px solid transparent !important;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #001f2a;
}

.login_button:hover {
  background-color: #001f2a;
  border: 1px solid transparent !important;
  color: #bdf8fb;
  transition: 0.5s !important;
}

.icon_button span {
  background: #1877f2 !important;
  border-radius: 20px;

  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.icon_button span img {
  filter: contrast(0.5) brightness(5.5);
}

.icon_button {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  backdrop-filter: blur(15px);
  background: #dbdfe3;
  border-radius: 20px;
  padding: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #1877f2;
}

.icon_button:hover {
  color: #1877f2 !important;
  text-decoration: none;
}

.facebook_button {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1400px) {
  .login_content {
    width: 50%;
  }
}

@media (max-width: 1199px) {
  .login_content {
    width: 75%;
  }

  .time-event .form-check-label {
    font-size: 12px;
  }

  .temp-head {
    flex-direction: column-reverse;
    gap: 20px;
  }
}

@media (max-width: 991px) {
  .login_content {
    width: 100% !important;
  }

  .temp-head {
    flex-direction: row;
    gap: 20px;
  }
}

@media (max-width: 575px) {
  .login_content {
    width: 100%;
  }

  .confirm_msg_content {
    flex-direction: column;
    gap: 20px;
  }

  .confirm_msg_content p {
    font-size: 13px;
  }
}

/* image upload */

.img_upload {
  visibility: hidden;
  position: absolute;
}

/* kaviya Css */
.registration_radio {
}

.registration_radio label {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #001f2a;
}

.registration_radio img {
  padding-left: 10px;
}

.registration_radio .form-check-input {
  padding: 8px;
  border: 1px solid #7e868d;
}

.registration_radio .form-check-input:checked {
  box-shadow: none;
  background-color: #012d3c;
  border: 1px solid #012d3c;
}

.registration_sec {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* .registration_sec_image {
  object-fit: cover;
  width: 1920px;
  height: 893px;
} */
.registration_body .eye_icon img {
  padding-left: 0px !important;
}

.registration_body {
  border-radius: 3px;
  padding: 16px;
  text-decoration: none;
  background: #f4f8fc !important;
  display: flex;
  flex-direction: column;
}

.registration_sec {
  padding: 0px 0px 18px 0px;
  white-space: nowrap;
  flex-wrap: wrap;
}

.registration_body.active {
  border: 1px solid rgba(125, 249, 255, 1);
  background: #ffffff;
}

.registration_info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 70px;
}

.registration_info p {
  font-weight: 500;
  font-size: 14px;
  color: #3e545c;
  margin-bottom: 0px;
}

.registration_info span {
  font-weight: 700;
  font-size: 14px;
  color: #11777e;
  padding-left: 5px;
}

.registration_info_1 {
  display: flex;
  gap: 5px;
  align-items: flex-start;
  flex-direction: column;
}

.registration_info_1 a {
  font-weight: 400;
  font-size: 13px;
  text-transform: capitalize;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: #696e70;
}

.registration_info a {
  text-decoration: none !important;
}

.registration_info a:hover {
  text-decoration: none !important;
}

.registration_radio .registration_body:hover {
  text-decoration: none !important;
}

.registration_alert h6 {
  font-weight: 400;
  font-size: 13px;

  color: #11777e !important;
}

.registration_alert h6 span {
  font-weight: 400;
  font-size: 13px;

  color: #11777e !important;
}

.registration_alert h6 {
  background: rgba(125, 249, 255, 0.25);
  border-radius: 3px;
  padding: 4px 8px;
  border: 1px solid transparent !important;
  width: fit-content;
}

.confirm_msg {
  border: 1px solid #dbdfe3;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5);
  background: #fff;
}

.add_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add_info_span a {
  font-weight: 600;
  font-size: 16px;
  color: #11777e;
  border-radius: 5px;
  padding: 5px 10px !important;
  background: #e5fdff;
  margin-right: 15px !important;
  text-decoration: none;
}

.add_info_img {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add_info_img img {
  border: 1px solid #4ed2d9;
  border-radius: 100px;
  background: #4ed2d9;

  padding: 12px;
}

.add_info_content {
  display: flex;
  align-items: center;
  gap: 15px;
}

.pre_notification ul {
  list-style-type: none;
  padding-left: 0px;
}

.pre_notification li {
  padding: 10px;
}

.pre_notification li h4 {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  margin-bottom: 0px;
}

.pre_notification li p {
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  color: #3e545c;
  margin-bottom: 0px;
}

.confirm_msg_content {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  padding: 10px;
  background: #fafafa;
  justify-content: space-between;
}

.pre_notification_1 li {
  padding: 10px 0px;
}

.pre_notification_1 li {
  padding-left: 0px !important;
}

.pre_notification .pre_notification_list {
  position: relative;
}

.pre_notification .pre_notification_list li {
  position: relative;
  padding-left: 80px;
}

.pre_notification .pre_notification_list .list_msg::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-image: url(./assets/images/registration_imgs/notification.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.pre_notification .pre_notification_list .list_msg:nth-child(2):before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-image: url(./assets/images/registration_imgs/notification_1.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #fff;
  z-index: 10;
}

.pre_notification .pre_notification_1 li {
  position: relative;
}

.pre_notification .pre_notification_1 li::before {
  content: "";
  position: absolute;
  top: 40px;
  left: -64px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;

  border: 1px solid #d1d4d7;
  border-radius: 50px;
  z-index: 10;
  background-color: #fff;
}

.pre_notification .pre_notification_1 li::after {
  content: "";
  position: absolute;
  top: 40px;
  left: -44px;
  transform: translateY(-50%);
  width: 45px;
  height: 1px;

  border: 1px solid #d1d4d7;
  border-radius: 50px;
}

.pre_notification .pre_notification_list {
  position: relative;
}

.pre_notification .pre_notification_list::after {
  content: "";
  position: absolute;
  top: 65px;
  left: 25px;
  border: 0;
  right: 0;
  width: 1px;
  background-color: #d1d4d7;
  height: 68%;
}

.modal_msg h4 {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #11777e;
  margin-bottom: 10px;
}

.modal_msg p {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #3e545c;
  margin-bottom: 0px;
}

.modal_msg .primary_btn {
  text-align: center;
}

.registration_modal .modal-header {
  padding: 0px !important;
}

.modal_button {
  text-align: center;
}

.model_content_1 {
  /* border: 1px solid #DBDFE3; */
  border-radius: 6px;
  padding: 35px 75px;
  box-shadow: none;
  background: #fff;
}

.model_content_1 h6 {
  font-weight: 600;
  font-size: 20px;
  color: #001f2a;
  padding-top: 30px;
}

.dashboard_model {
  display: flex;
  align-items: center;
  gap: 20px;
}

.link_model .modal-header {
  padding: 0px;
}

.link_model .modal-body {
  padding: 20px 0px 0px 0px;
}

.link_model p {
  font-weight: 500;
  font-size: 15px;
  color: #3e545c;
}

.dashboard_link_model {
  box-shadow: none;
}

.dashboard_link_model.active {
  border: 1px solid #4ed2d9;

  box-shadow: none;
  border-radius: 6px;
}

.tab_button {
  border: 1px solid #7df9ff;
  border-radius: 6px;
}

.nav-tabs .nav-link {
  background: #fff;
  color: #001f2a !important;
}

.nav-tabs .nav-link.disabled {
  opacity: 0.5;
}

.nav-tabs .nav-link.active {
  border-radius: 3px;
  padding: 8px 12px;
  background: #001f2a;
  color: #fff !important;
}

.tab_button .nav-item {
  padding: 10px;
}

.tab_button .nav-link {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #fff;
}

.nav-tabs .nav-link {
  border: none !important;
}

.modal_text_box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modal_form .form-label {
  font-weight: 600;
  font-size: 16px !important;
  text-align: center;
  color: #001f2a;
}

.modal_form .form-control {
  box-shadow: none;
  border: 1px solid #d1d4d7;
  padding: 10px;
  width: 90%;
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  border-radius: 6px;
}

.sec_tabs h4 {
  font-weight: 700;
  font-size: 20px;
  color: #001f2a;
}

.sec_tabs p {
  font-weight: 500;
  font-size: 15px;
  color: #3e545c;
  margin-bottom: 0px;
}

.sec_tabs {
  display: flex;
  align-items: center;
  gap: 15px;
}

.sec_tab_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.white-box-2 {
  padding: 20px;
}

.dashboard_p_tag {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #001f2a !important;
  margin-bottom: 0px;
}

.dashboard_p_tag span {
  font-weight: 400;
  font-size: 14px;
  color: #3e545c;
}

.model_form_tab {
  padding-left: 20px;
}

@media (max-width: 1199px) {
  .pre_notification .pre_notification_list::after {
    height: 67%;
  }
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
  .add_info {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start !important;
  }

  /* .confirm_msg_content {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  } */

  .pre_notification .pre_notification_list::after {
    height: 68%;
  }
}

@media (max-width: 575px) {
  .registration_info p {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .pre_notification .pre_notification_list::after {
    height: 71% !important;
  }

  .add_info_span {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .pre_notification li p {
    font-size: 12px;
  }

  .pre_notification li h4 {
    font-size: 14px;
  }

  .add_info_content img {
    width: 30px;
    height: 30px;
  }

  .add_info_content {
    gap: 10px;
  }

  .registration_info {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
@media (max-width: 455px) {
  .pre_notification .pre_notification_list::after {
    height: 76% !important;
  }
}
/* .pre_notification .pre_notification_list::after {
  
  }
  .add_info_span {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .pre_notification li p {
    font-size: 12px;
  }
  .pre_notification li h4 {
    font-size: 14px;
  }
  .add_info_content img {
    width: 30px;
    height: 30px;
  }
  .add_info_content {
    gap: 10px;
  } */

/* analythics */
.analytics .analytics_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select_box {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-right: 10px;
}

.select_box label {
  font-weight: 400;
  font-size: 15px;

  color: #001f2a;
}

.analytics .form-select {
  border: 1px solid #d1d4d7;
  border-radius: 10px;
  padding: 10px;
  font-weight: 500;
  font-size: 15px;
  color: #001f2a;
}

.analytics .form-select:focus {
  border: 1px solid #dee2e6;
}

.traffic_info p {
  font-weight: 400;
  font-size: 14px;
  color: #394346;
  margin-bottom: 0px;
  white-space: nowrap;
}

.traffic_info span {
  font-weight: 500;
  font-size: 14px;
  color: #001f2a !important;
}

.traffic_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid #d1d4d7;
  border-radius: 3px;
  padding: 8px;
  flex-grow: 1;
}
.traffic_info div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.traffic_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.traffic_info_1 {
  display: flex;
  align-items: center;
  gap: 15px;
  border-left: 1px solid #d1d4d7;
  padding-left: 10px;
}

.live_webinar h4 {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #394346;
}

.live_webinar_sec {
  display: flex;
  gap: 20px;
  align-items: center;
}

.live_webinar_2 {
  display: flex;
  align-items: center;
  gap: 15px;
}

.live_webinar_2 p {
  font-weight: 500;
  font-size: 16px;
  color: #3c4043;
  margin-bottom: 0px;
}

/* .live_webinar_2 img {
  border-radius: 10px;
  padding: 10px;
  background: #dbdfe3;
} */
.chat-border {
  border: 1px solid #dbdfe3;
  border-radius: 3px;
  padding: 12px;
}
.webinar_2_info {
  /* border: 1px solid #d1d4d7;
  border-radius: 10px;
  padding: 20px; */
  margin: 10px;
  height: 95%;
}

.webinar_2_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dbdfe3;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
}

.webinar_2_info p {
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  color: #3c4043;
  margin-bottom: 0px;
}

.live_webinar_2 p {
  font-weight: 400;
  font-size: 14px;
  color: #394346;
  margin-bottom: 0px;
}

.monetization_main_box {
  padding: 33px 20px !important;
}

.monetization_box {
  border: 1px solid #dbdfe3;
  border-radius: 10px;
  padding: 20px;
  height: 100%;
}

.monetization_box h4 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px;
  color: #001f2a;
}

.monetization_box_content {
  display: flex;
  align-items: center;
  gap: 15px;
  border: 1px solid #dbdfe3;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
}

.monetization_box_1 .monetization_box_content {
  justify-content: space-between;
  padding: 10px 20px !important;
}

.monetization_box_content h6 {
  font-weight: 700;
  font-size: 32px;
  color: #001f2a;
}

.monetization_box_content h6 span {
  font-weight: 500;
  font-size: 16px;
  color: #3e545c;
}

.monetization_box_content p {
  font-weight: 500;
  font-size: 16px;
  color: #3c4043;
  margin-bottom: 0px;
}

.monetization_box_content img {
  border: 1px solid #dbdfe3;
  border-radius: 5px;
  padding: 20px;
  background: #dbdfe3;
}

.monetization_head {
  display: flex;
  flex-direction: column;
}

.monetization_box_2 p {
  font-weight: 600;
  font-size: 16px;

  color: #3e545c;
  margin-bottom: 0px;
}

.monetization_box_2 {
  /* display: flex;
  align-items: center;
  gap: 10px; */
}

.monetization_box_2 .primary_btn {
  padding: 6px 14px 6px 14px;
}

.sales_content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sales_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}

.sales_sec:first-child {
  border-bottom: 2px dotted #dbdfe3;
  padding-bottom: 10px;
}

.sales_img {
  border-radius: 10px !important;
  padding: 10px !important;
  background: #d6ffff !important;
  border: none !important;
}

.monetization_box_content_1 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sales_main {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add_content_1 h4 {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  margin-bottom: 0px;
  text-align: center;
}

.add_content_1 p {
  font-weight: 600;
  font-size: 12px;
  color: #3c4043;
  margin-bottom: 0px;
  text-align: center;
}

.add_content_1 {
  display: flex;
  align-items: center;
}

.add_sub_content {
  border-right: 1px solid #d1d4d7;
  padding: 0px 40px;
}

.active_select .analytics_select {
  border: 1px solid #dbdfe3;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
}

.active_entries {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.active_entries p {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #3c4043;
  margin-bottom: 0px;
}

.analytics_select .primary_btn {
  margin-bottom: -28px;
}

.monetization_box_2 .tab_button {
  border: none;
}

.monetization_box_2 .tab_button .nav-item {
  padding: 0px;
}

@media (max-width: 1399px) {
  .traffic_box {
    flex-wrap: wrap;
  }

  .webinar_2_content {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .time-schedule p {
    font-size: 12px;
  }

  .schedule-details .input-group {
    padding: 0px;
  }

  .LastMinuteChecklist1-box .head-def .box1 {
    height: 197px;
  }
}

@media (max-width: 1199px) {
  .monetization_box_content {
    flex-direction: column;
    align-items: flex-start;
  }

  .analytics .pre_notification .pre_notification_list::after {
    height: 85%;
  }

  /* .analytics .confirm_msg_content {
    flex-wrap: wrap;
  } */
  .LastMinuteChecklist1-box {
    width: 101%;
  }

  .LastMinuteChecklist1-box .head-def .box1 {
    height: unset;
  }

  .LastMinuteChecklist1-box .head-def .box1 .def1 p {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .monetization_box_content {
    flex-direction: row;
  }

  .LastMinuteChecklist1-box .head-def .box1 .def1 h6 {
    font-size: 19px;
  }

  .LastMinuteChecklist1-box .head-def .box1 .def1 p {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .analytics .analytics_select {
    flex-direction: column;
  }

  .select_box {
    width: 100%;
    padding-bottom: 15px;
  }

  .analytics_select .primary_btn {
    margin-bottom: 0px;
  }

  .monetization_box_1 .monetization_box_content {
    flex-direction: column;
  }

  .temp-head {
    flex-direction: column-reverse;
    gap: 20px;
  }
}

@media (max-width: 567px) {
  .traffic_info {
    display: flex;
    align-items: center;
    gap: 3px;
    border: 1px solid #d1d4d7;
    border-radius: 3px;
    padding: 5px;
  }

  .analytics {
    padding: 10px;
  }

  .traffic_info_1 {
    display: flex;
    align-items: center;
    gap: 5px;

    padding-left: 4px;
  }

  .analytics .pre_notification .pre_notification_list::after {
    height: 86%;
  }

  .add_content_1 {
    flex-wrap: wrap;
  }

  .add_sub_content {
    border-right: 1px solid transparent;

    padding: 0px 14px;
  }

  .LastMinuteChecklist1-box .head-def .box1 .def1 {
    flex-direction: column;
    text-align: center;
  }
}

/* registration_page_2 */
.dashborad_button {
  display: flex;
  gap: 20px;
  align-items: center;
}

.registration_nav_sec ul {
  list-style-type: none;
  padding-left: 0px;
}

.registration_nav_sec .registration_list {
  display: flex;
  align-items: center;
  gap: 20px;
  /* justify-content: space-between; */
}

.registration_nav_content {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 3px;
  padding: 10px;
  background: #ffff;
  border: 1px solid #e0e0e0;
}

.registration_nav_content.active {
  background: #fff;
}

.registration_list li {
  position: relative;
}

.registration_nav_content img:first-child {
  width: 18px !important;
}

.registration_nav_content img {
  width: 16px !important;
}

.registration_list {
  position: relative;
}

.registration_list::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #7df9ff;
  top: 21px;
  z-index: -1;
  display: block;
}

/* .registration_list li:nth-child(2)::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #7df9ff;
  top: 21px;
  left: 133px;
  z-index: -1;
  min-width: 100%;
}

.registration_list li:nth-child(3)::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #7df9ff;
  top: 21px;
  left: 123px;
  z-index: -1;
  min-width: 100%;
}
.registration_list li:nth-child(4)::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #7df9ff;
  top: 21px;
  left: 123px;
  z-index: -1;
  min-width: 100%;
}
.registration_list li:nth-child(5)::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #7df9ff;
  top: 21px;
  left: 123px;
  z-index: -1;
  min-width: 100%;
} */
.registration_list li:last-child::before {
  display: none !important;
}

.registration_nav_content p {
  font-weight: 600;
  font-size: 14px;
  color: #001f2a;
  margin-bottom: 0px;
  white-space: nowrap;
}

.registration_form p {
  font-weight: 700;
  font-size: 20px;
  color: #001f2a;
  margin-bottom: 0px;
}

.registration_form {
  border-radius: 20px;
  padding: 20px;
  background: #d6ffff;
}

.registration_form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.template_btn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.template_btn p {
  font-weight: 700;
  font-size: 16px;
  color: #28b42b;
  margin-bottom: 0px;
}

.template_btn .primary_btn {
  border-radius: 10px;
  padding: 5px 10px;
  background: #11777e;
}

.add_fields {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add_fields .form-control {
  border: 1px solid transparent !important;
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  padding: 0px;
}

.add_fields .input-group {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px;
}

/* .form_fields .input-group:focus{
background-color: #D6FFFF !important;
border-radius: 10px;

} */

.add_fields .input-group-text {
  border: 1px solid transparent !important;
  background: none !important;
}

.registration_forms h4 {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
}

.form_fields {
  border: 1px solid #dbdfe3;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
}

.form_fields h4 {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
}

.checked_field h4 {
  font-weight: 600;
  font-size: 16px;
  color: #11777e;
}

.checked_field p {
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  color: #3e545c;
  margin-bottom: 0px;
}

.registration_checked.active {
  border: 1px solid #dbdfe3;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
}

.registration_checked {
  display: flex;
  gap: 15px;
  align-items: flex-start;
  padding: 20px;
}

.registration_checked_1 {
  display: flex;
  gap: 15px;
  align-items: flex-start;
  padding: 20px;
}

.registration_checked_1.active {
  border: 1px solid #dbdfe3;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
}

.registration_checked_1 {
  display: flex;
  gap: 15px;
  align-items: flex-start;
}

.registration_rewrite {
  border-radius: 10px;
  background: #dbdfe3;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 33px 29px;
}

.registration_rewrite img {
  border: 1px solid #d1d4d7;
  border-radius: 100px;
  padding: 10px;
  box-shadow: 1px 1px 2px 0 rgba(209, 212, 215, 0.5),
    2px 2px 7px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}

.checked_field_1 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checked_field_1 p {
  border-radius: 10px;
  padding: 8px 16px;
  background: #fff;
  margin-bottom: 0px;
}

.checked_field_1 p:hover {
  background: #7df9ff;
}

.checked_field_1 a {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  text-decoration: none;
}

.link_model .model_tab .nav-link.active {
  background: none !important;
}

.model_tab {
  display: flex;
  justify-content: center;
}

.model_tab {
  border-bottom: 1px solid transparent;
}

.footer_img {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding-top: 120px;
}

.registration .accordion-button {
  background: #fff;
  border: 1px solid #dbdfe3;
  border-radius: 3px !important;
}

.webinar_foot {
  padding-top: 0px;
}

@media (max-width: 1599px) {
  .registration_rewrite {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 1399px) {
  .checked_field_1 {
    flex-wrap: wrap;
  }
}

@media (max-width: 1199px) {
  .registration_nav_sec .registration_list {
    flex-wrap: nowrap;
  }

  .registration_list li:first-child::after {
    display: none;
  }

  .registration_list li:nth-child(2) ::after {
    display: none;
  }

  .registration_list li:nth-child(3) ::after {
    display: none;
  }

  .registration_nav_sec .registration_list {
    gap: 26px;
  }

  .registration_checked {
    display: flex;
    gap: 6px;
    align-items: flex-start;
    padding: 10px;
  }

  .registration_checked_1 {
    display: flex;
    gap: 6px;
    align-items: flex-start;
    padding: 10px;
  }
}

@media (max-width: 991px) {
  /* .registration_nav_sec {
    padding-top: 100px;
  } */
}

@media (max-width: 767px) {
  .add_fields {
    flex-direction: column;
    gap: 0px;
  }
}

@media (max-width: 575px) {
  .registration_form {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .dashboard_future {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .registration_checked {
    width: 318px;
  }

  .registration_rewrite {
    flex-wrap: nowrap;
  }

  .checked_field_1 {
    flex-wrap: nowrap;
    gap: 5px;
  }

  .checked_field_1 p {
    border-radius: 10px;
    padding: 5px 10px;
    background: #fff;
    margin-bottom: 0px;
  }

  .registration_rewrite {
    border-radius: 10px;
    background: #dbdfe3;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 26px 16px;
  }

  .checked_field_1 a {
    font-size: 12px;
  }
}

/* configration */
.config_form {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.25);
  background: #fff;
  height: 418px;
  width: 50%;
}

.configration_sec {
  margin-top: 0px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.configuration_flex {
  display: flex;
  flex-direction: column;
}

.tab_content .nav-pills .nav-link {
  border: 1px solid transparent !important;
  padding: 20px;
}

.tab_content .nav-link.active {
  border: 1px solid transparent !important;
  border-radius: 10px !important;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}

.tab_content .nav-pills .nav-item {
}

.tab_content .nav-link.active h6 {
  color: #11777e;
}

.configuration_tab_content {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.configuration_tab_content h6 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px;

  text-align: left;
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
}

.configuration_tab_content p {
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  color: #3e545c;
  margin-bottom: 0px;
  text-align: left;
}

.tab_home_content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.tab_content {
  width: 50%;
}

.tab_content_1 {
  border-radius: 10px;
  padding: 20px;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  height: 418px;
  border: 1px solid #f0f0f0;
}

.user_role h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  color: #3e545c;
  margin-bottom: 0px;
}

.webinar_user_content {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #dbdfe3;
  border-radius: 20px;
  padding: 48px;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  justify-content: center;
}

.webinar_user_content p {
  font-weight: 600;
  font-size: 20px;
  color: #001f2a;
  margin-bottom: 0px;
}

.user_content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.webinar_user_content:first-child p {
  font-weight: 600;
  font-size: 20px;
  color: #001f2a;
}

.webinar_user_content p {
  font-weight: 600;
  font-size: 20px;
  color: #7e868d;
}

.configuration_tab {
  display: flex;
  align-items: center;
  gap: 30px;
}

.configuration_tab .nav-pills {
  width: 70%;
}

.configuration_webinar .webinar-width {
  width: 50%;
}

/* ----------------------------------------------------dashboard-change-start------------------------------------------------------- */
.dashboard-grid {
  display: flex;
}

.scroll-auto {
  height: 255px;
  overflow: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

/* .scroll-auto1 {
  height: 235px;
  overflow: scroll;
} */
.meeting_grid_card {
  border-bottom: 1px solid #d4d4d4;

  /* padding-bottom: 12px; */
}

.meeting_grid_card:last-child {
  border-bottom: 0;
}

.dashbord-right-body_1 {
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border: 1px solid #dbdfe3;
  border-radius: 20px;
  height: 100%;
}

.meeting_grid_card .dashbord-right-body1 {
  padding-top: 12px !important;
  padding-bottom: 0;
}

.grid_dashbord .row > div {
  margin-bottom: 20px;
}

.dashbord-right-body1 {
  padding: 40px 20px 20px 20px;
}

.dashbord-right-body_head {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 10px;
}

.dashbord-right-body_head h2 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 24px;
  color: #001f2a;
}

.dashbord-right-body_head p {
  word-break: break-all;
}

.dashbord-right-body_head_1 h4 {
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  color: #001f2a;
}

.dashbord-right-body_head_1 p {
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  color: #001f2a;
}

.dashbord-right-body_footer {
  border-top: 1px solid #d1d4d7;

  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.share_icon svg {
  font-size: 20px;
  color: #001f2a;
  cursor: pointer;
}

.share-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  /* border-top: 1px solid #dde0e2; */
}

.dashbord-right-body_footer.analytics_btn {
  justify-content: end;
}

.grid_dashbord .dashbordrightbody-header ul li a {
  padding: 0 13px;
}

.dashboard_right_body_1 {
  display: flex;
  align-items: center;
  gap: 12px;
}

.grid_Row_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.grid_Row_list .grid_Row_list_child .select-search-input {
  min-width: 250px;
  height: 39px !important;
  flex: 1;
}
.grid_Row_list_child .registration_nav_content {
  width: 40px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 3px;
  padding: 10px;
  background: #ffff;
  border: 1px solid #e0e0e0;
}
.grid_Row_list .grid_Row_list_child {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.grid_Row_list .grid_Row_list_child .registration_nav_content img {
  width: 54px !important;
}
.grid_Row_list .grid_Row_list_child .datapick {
  display: block;
  flex: 1;
}
.grid_Row_list .grid_Row_list_child .select-search-container {
  flex: 1;
}
.grid_Row_list .input-group {
  width: 100%;
  min-width: 250px;
  border: 1px solid #d1d4d7;
  border-radius: 5px;
  flex: 1;
}
.grid_Row_list .filterSessions {
  flex: 1 !important;
}
.grid_Row_list .input-group .form-control {
  border: 1px solid transparent;
  min-height: 39px;
}
.grid_Row_list .input-group .rdt .form-control {
  border-radius: 0 !important;
}
.grid_Row_list .input-group .form-control::placeholder {
  color: #001f2a;
  font-weight: 600;
  font-size: 14px;
}

.grid_Row_list .input-group .input-group-text {
  border: 1px solid transparent;
  background: transparent;
}

.grid_Row_list .input-group .input-group-text svg {
  color: #001f2a;
}
.grid_Row_list
  .grid_Row_list_child
  .react-datepicker__input-container
  .react-datepicker__calendar-icon {
  top: 2px;
}
.grid_Row_list .grid_Row_list_child .react-datepicker-wrapper {
  flex: 1;
}
.grid_Row_list .grid_Row_list_child .react-datepicker-wrapper {
  width: 100%;
}
.grid_Row_list .grid_Row_list_child .react-datepicker-popper {
  z-index: 100;
}
.icon_grid_list {
  display: flex;
  gap: 30px;
}

.icon_grid_list svg {
  font-size: 24px;
  color: #001f2a;
  cursor: pointer;
}

.icon_listGrid.active {
  color: #11777e !important;
}

.filterSessions {
  width: 100%;
  min-width: 200px;
  color: #001f2a;
  font-weight: 400;
  font-size: 13px;
  border: 1px solid #dbdfe3 !important;
  border-radius: 3px;
}
.datapick {
  display: flex;
  align-items: center;
  gap: 20px;
}
.datapick_head {
  border: 1px solid #dbdfe3;
  border-radius: 3px;
  padding: 10px;
  font-weight: 400;
  font-size: 13px;
  color: #001f2a;
  width: 100%;
  min-width: 250px;
  min-height: 39px;
}
.datapick_head::placeholder {
  font-weight: 400;
  font-size: 13px;
  color: #696e70;
}
.datapick .react-datepicker__month-container {
  background: #e5fdff !important;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1) !important;
}
.datapick .react-datepicker__header {
  background: #e5fdff !important;
  border-bottom: 1px solid transparent !important;
}
.datapick .react-datepicker__day--selected:hover {
  background: #003a4e !important;
  color: #fff !important;
}
.datapick .react-datepicker__day--selected {
  background: #003a4e !important;
  color: #fff !important;
}
.datapick .react-datepicker__day:hover {
  background: #003a4e !important;
  color: #fff !important;
}
.datapick .react-datepicker__day--keyboard-selected {
  background: #003a4e !important;
  color: #fff !important;
}
.react-datepicker__navigation-icon::before {
  border-color: #003a4e !important;
}
.datapick .react-datepicker {
  border: 1px solid transparent !important;
}
.react-datepicker__close-icon::after {
  background-color: #003a4e !important;
}
.datapick_head:focus-visible {
  outline: 1px solid #dbdfe3 !important;
}
.datapick .react-datepicker__input-container .react-datepicker__calendar-icon {
  top: 3px;
}
.nomeeting_img {
  background: #fff;
  border: 1px solid #dbdfe3;
  border-radius: 6px;
  padding: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.Webinar-check .form-check-input {
  border-radius: unset !important;
}
.Webinar-check .form-check-input:checked[type="checkbox"] {
  background-color: #29cf3a;
  border-color: #29cf3a;
}
.Webinar-check .form-check-input:focus {
  border-color: #d9d9d9 !important;
}
.mail_editor .rdw-editor-wrapper {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
}
.mail_editor .rdw-editor-toolbar {
  background: #f1f1f1;
  column-gap: 20px;
  align-items: center;
}
.mail_editor .rdw-option-wrapper {
  background: transparent;
}
.mail_editor .rdw-dropdown-selectedtext {
  background: #f1f1f1;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #000;
  text-decoration: none;
}
.mail_editor .rdw-inline-wrapper,
.mail_editor .rdw-block-wrapper,
.mail_editor .rdw-fontsize-wrapper,
.mail_editor .rdw-fontfamily-wrapper,
.mail_editor .rdw-list-wrapper,
.mail_editor .rdw-colorpicker-wrapper,
.mail_editor .rdw-link-wrapper,
.mail_editor .rdw-embedded-wrapper,
.mail_editor .rdw-emoji-wrapper,
.mail_editor .rdw-image-wrapper,
.mail_editor .rdw-remove-wrapper,
.mail_editor .rdw-history-wrapper {
  margin-bottom: 10px !important;
}
.mail_editor .public-DraftStyleDefault-block span {
  background: transparent !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #696e70 !important;
}
.mail_editor .rdw-option-wrapper:active {
  box-shadow: none;
}
.mail_editor .rdw-option-wrapper:hover,
.rdw-dropdown-wrapper:hover {
  box-shadow: none !important;
  background: transparent;
}
.dashbordrightbody-body1 {
  border-bottom: 1px solid #dbdfe3;
}
.dashbordrightbody-body1:last-child {
  border-bottom: 1px solid transparent;
}
.scroll-auto1 .dashbordrightbody-list {
  align-items: center;
}
/* ----------------------------------------------------dashboard-change-end------------------------------------------------------- */
/* ----------------------------------------------------Analytics-change-start------------------------------------------------------- */
.user_name {
  font-size: 16px;
  font-weight: 500;
  color: #001f2a;
}

.Duration p {
  font-weight: 400;
  font-size: 13px;
  color: #696e70;
}

.dashbord-right-body_Analytics1 .dashbordrightbody-body {
  padding: 20px;
}

.analytics_tab_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.analytics_tab_content_1 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.analytics_tab_content_1 p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  color: #001f2a;
  text-align: start;
}
.analytics_tab_content_2 p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  color: #001f2a;
  text-align: start;
}
.analytics_tab_content_1 h6 {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  color: #394346;
}

.learning_analytics .nav-pills .nav-link {
  border: 1px solid #dbdfe3 !important;
  border-radius: 3px !important;
  padding: 12px 16px !important;
  background: #fff !important;
}

/* .analytics_tab_content_2 {
  background-color: #c5e1e2;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  border-radius: 50%;
} */

.analytics_tab_content_2 svg {
  color: #11777e;
}

.learning_analytics .nav-pills .nav-link.active {
  background: #001f2a !important;
}
.learning_analytics .nav-pills .nav-link.active .analytics_tab_content_1 h6 {
  color: #fff !important;
}
.learning_analytics .nav-pills .nav-link.active .analytics_tab_content_2 p {
  color: #fff !important;
}
.learning_analytics .nav-pills {
  gap: 20px;
}
.learning_analytics .rdt_TableHeadRow {
  background-color: #eff3f7 !important;
  border-radius: 3px 3px 0 0 !important;
  padding: 12px !important;
  border-bottom-style: none !important;
}
.learning_analytics .rdt_TableCol_Sortable {
  font-weight: 400;
  font-size: 13px;
  color: #394346;
}
.overview_text h2 {
  font-weight: 400;
  font-size: 14px;
  color: #394346;
  padding: 20px 0px 10px 0px;
  margin-bottom: 0;
}

.users_tab {
  display: flex;
  gap: 10px;
  align-items: center;
}

.user_dp {
  border: 1px solid #000;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  border-radius: 5px;
}

.users_tab h6 {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #394346;
}

.users_tab p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 11px;
  color: #394346;
}

/* .rdt_TableCell {
  display: block !important;
} */
.progress {
  height: 10px;
}

.progress-bar {
  background-color: #4ed2d9;
}

.Activity_Score {
  display: flex;
  gap: 3px;
}

.Activity_Score div {
  width: 12px;
  height: 12px;
  border: 1px solid #000;
}

.Activity_Score .active1 {
  background: #14777e;
}

.Activity_Score .active2 {
  background: #1e5a62;
}

.Activity_Score .active3 {
  background: #1b3f48;
}

.Activity_Score .active4 {
  background: #12252f;
}

.Activity_Score .active5 {
  background: #1b3f48;
}

.Activity_Score .active6 {
  background: #1b3f48;
}

.online_btn {
  background: #14777e;
  padding: 3px 10px;
  border-radius: 50px;
  border: 1px solid transparent;
  color: #fff;
}

.offline_btn {
  background: #a10606d0;
  padding: 3px 10px;
  border-radius: 50px;
  border: 1px solid transparent;
  color: #fff;
}

.rdt_TableRow {
  padding: 10px 0px;
}

.Talk_time p {
  margin-bottom: 0;
}

.Webcam_time p {
  margin-bottom: 0;
}

.Message {
  margin-bottom: 0;
}

.Emojis {
  margin-bottom: 0;
}

.Raise_Hands {
  margin-bottom: 0;
}

.rdt_TableHead {
  font-size: 16px !important;
  font-family: 600 !important;
  color: #001f2a !important;
}

.rdt_TableCol_Sortable:hover {
  color: #001f2a !important;
  opacity: 1 !important;
}

.users_tab1 {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  position: absolute;
  left: 0;
}

.userFixed {
  position: sticky;
  display: flex;
  align-items: center;
  left: 0;
  background-color: #ffffff;
  padding: 12px 10px 12px 0;
  z-index: 9;
}

.userFixed h6 {
  margin-left: 8px;
  text-transform: capitalize;
}

.users_tab_line {
  width: 80%;
}

.user_dp1 {
  border: 1px solid #000;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  border-radius: 5px;
}

.users_tab1 h6 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  color: #11777e;
}

.line1 {
  width: 100%;
  height: 10px;
  background: #d4d4d4;
  border: 1px solid transparent;
  border-radius: 10px;
  position: relative;
  margin-top: 18px;
}

.line1_position {
  position: relative;
}

.tab_3_content {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 20px;
}

.likes_event {
  position: absolute;
  background-color: #c5e1e2;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  border-radius: 50%;
  z-index: 1;
  top: -10px;
  /* right: 159px; */
}

/* .video_view{
  position: absolute;
} */
.scroll_bar {
  width: calc(100vh - 500px) !important;
  height: 300px !important;
}

.timelineScroll > div {
}

.timeline-item {
  padding-bottom: 20px;
  padding-top: 20px;
}

/* ----------------------------------------------------Analytics-change-end------------------------------------------------------- */
/* ----------------------------------------------------Analytics-Polls-start------------------------------------------------------ */
.Polls_acc .accordion-header .Total_count {
  display: flex;
  align-items: center;
  gap: 10px;
}
.Polls_acc .accordion-button {
  background-color: #fff;
  border: 1px solid #bdc1c5;
  border-radius: 6px !important;
}
.Polls_acc .accordion-header .Total_count h6 {
  font-weight: 600;
  font-size: 16px;
  color: #11777e;
  border-radius: 5px;
  padding: 5px 10px !important;
  background: #e5fdff;
  margin-right: 15px !important;
  text-decoration: none;
}

.confirm_msg_content p {
  margin-bottom: 0;
}

.analytics-page {
  margin-bottom: 100px;
}

/* ----------------------------------------------------Analytics-Polls-end------------------------------------------------------ */
/* ----------------------------------------------------New-user-page-start----------------------------------------------------- */
.newuser h4 {
  font-weight: 600;
  font-size: 32px;
  line-height: 81%;
  letter-spacing: -0.02em;
  text-align: center;
  color: #001f2a;
}

.newuser .login_content .input-group {
  border-radius: 15px !important;
  padding: 5px !important;
  backdrop-filter: blur(75px) !important;
  background: #dbdfe3 !important;
}

.newuser .login_content .form-control {
  padding: 0 !important;
  padding-left: 10px !important;
}

.newuser .login_content .input-group-text {
  padding: 0 !important;
}

.newuser .second-btn {
  width: 100%;
  border-radius: 100px;
  padding: 10px 20px;
  background: #7df9ff;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #001f2a;
  text-decoration: none;
  border: 1px solid transparent;
}

.newuser .second-btn:hover {
  background: #001221;
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 16px;
}

/* ----------------------------------------------------New-user-page-end----------------------------------------------------- */
/* ----------------------------------------------------configration-page-edit-start----------------------------------------------------- */
.thankyou_accbody {
  min-height: 272px;
}

.thankyou_accbody_1 {
  min-height: 250px;
}

.thankyou_accbody_2 {
  min-height: 450px;
}

.thankyou_accbody_3 {
  min-height: 350px;
}

.Auto_subscription {
  display: flex;
  align-items: flex-start;
}

.tab_flex {
  display: flex;
  width: 90%;
  gap: 20px;
}

.thankyou_accbody_3 .img-width {
  width: 100%;
}

.gray-body {
  background: #dbdfe3;
  border-radius: 0 0 10px 10px;
  padding: 20px;
  margin-top: 40px;
}

.polls-add-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid transparent;
  padding: 15px 12px;
  backdrop-filter: blur(75px);
  background: #fff;
  font-weight: 600;
  font-size: 15px;
  text-align: start;
  color: #3e545c;
}

.polls-add-btn.active {
  background: #d6ffff;
}

.polls-Add {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.poll-question {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 45%;
}

.poll-question p {
  background: #e5fdff;
  border-radius: 10px;
  padding: 15px;
  font-weight: 600;
  font-size: 14px;
  color: #11777e;
  margin-bottom: 0;
  white-space: nowrap;
}

.poll-question > div {
  width: 100%;
}

.poll-question .input-group {
  border-radius: 10px;
  padding: 5px 8px;
  backdrop-filter: blur(75px);
  background: #fff;
  border: 1px solid transparent;
}

.poll-question .form-control {
  background: transparent;
  border: 1px solid transparent;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.poll-question .form-control::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.poll-question .input-group-text {
  background: transparent;
  border: 1px solid transparent;
  padding: 0;
}

.polls-qa {
  display: flex;
  align-items: start;
  gap: 40px;
  width: 100%;
}

.poll-answer {
  width: 37%;
}

.poll-answer .input-group {
  border-radius: 10px;
  padding: 5px 8px;
  backdrop-filter: blur(75px);
  background: #fff;
  border: 1px solid transparent;
}

.poll-answer .form-control {
  background: transparent;
  border: 1px solid transparent;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.poll-answer .form-control::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.poll-answer .input-group-text {
  background: transparent;
  border: 1px solid transparent;
  padding: 0;
}

/* ----------------------------------------------------configration-page-edit-end----------------------------------------------------- */
.tab_flex > div.active {
  background: #fff;
}

.tab_flex > div.active h6 {
  color: #11777e;
}

/* --------------------------------------------------------live page-start-------------------------------------------------------------- */
.live-content {
  display: flex;
  gap: 50px;
}

.placeholder::placeholder {
  font-weight: 600;
  font-size: 15px;
  color: #11777e;
}

.placeholder {
  background: #fff;
  border: 1px solid transparent;
}

.Live_accbody {
  min-height: 360px;
}

.model_polls .modal-header {
  padding: 0;
  border-bottom: 1px solid transparent;
}

.model_polls .modal-title {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  color: #001f2a;
}

.model_polls .form-label {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  margin-bottom: 0;
  padding-bottom: 5px;
}

.model_polls .form-control {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
  margin-bottom: 15px;
}

.model_polls .form-control::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.Add_option {
  border-radius: 10px;
  padding: 10px;
  background: #d6ffff;
  font-weight: 600;
  font-size: 15px;
  color: #001f2a;
  width: 50%;
}

.Add_option span {
  padding-left: 10px;
}

.model_btn_1 {
  border-radius: 100px;
  padding: 10px 20px;
  background: #dbdfe3;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #001f2a;
  text-decoration: none;
}

.model_btn {
  display: flex;
  margin-top: 30px;
  gap: 20px;
}

.model_btn .model_btn_1:hover {
  background: #7df9ff;
  color: #001f2a;
  text-decoration: none;
}

.model_Productoffer .modal-header {
  padding: 0;
  border-bottom: 1px solid transparent;
}

.model_Productoffer .modal-title {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  color: #001f2a;
}

.model_Productoffer .form-label {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  margin-bottom: 0;
  padding-bottom: 5px;
}

.model_Productoffer .form-control {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
  margin-bottom: 15px;
}

.model_Productoffer .form-control::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.file-drop-area {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  max-width: 100%;
  padding: 6px;
  border: 1px solid transparent;
  border-radius: 10px;
  transition: 0.2s;
  border-radius: 3px;
  background: #001f2a;
}

.choose-file-button {
  flex-shrink: 0;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3px;
  /* padding: 8px 15px; */
  margin-right: 10px;
  font-size: 15px;
  text-transform: uppercase;
}

.file-message {
  font-weight: 500;
  font-size: 13px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}

.file-explain {
  font-weight: 600;
  font-size: 12px;
  text-align: end;
  color: #7e868d;
  margin-bottom: 0;
  padding-top: 8px;
}

.file-explain a {
  text-decoration: none;
  color: #0fcdd6;
}

.switch-control .form-check-input:checked {
  background-color: #11777e;
}

/* .switch-control .form-switch .form-check-input {
  background: url(./assets/images/switch.png);

  background-repeat: no-repeat;
} */
.switch-control .form-switch .form-check-input {
  border: 1px solid #11777e;
}

.new-tab {
  font-weight: 500 !important;
  font-size: 12px !important;
  text-align: center !important;
  color: #7e868d !important;
  margin-bottom: 0 !important;
  white-space: nowrap !important;
  margin-top: 22px;
}

.button_link .input-group-text {
  padding: 0;
  border: 1px solid transparent;
  background: transparent;
  padding-left: 10px;
  font-size: 14px;
  color: #7e868d;
  font-weight: 500;
}

.button_link .form-control {
  margin-bottom: 0;
  padding: 0;
  border: 1px solid transparent;
  font-size: 14px;
  color: #7e868d;
  font-weight: 500;
}

.button_link {
  padding: 0px;
  border: 1px solid #d1d4d7;
  border-radius: 10px;
}

.button-switch {
  display: flex;
  align-items: start;

  gap: 10px;
}

.Broadcast_sales {
  display: flex;
}

.Broadcast_sales h6 {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.Broadcast_sales p {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #3e545c;
  margin-bottom: 0;
}

.units {
  border-radius: 10px;
  padding: 10px 20px;
  background: #f1f2f4;
  font-weight: 500;
  font-size: 15px;
  color: #3e545c;
  width: 30%;
}

.units p {
  margin-bottom: 0;
}

.border-col {
  border: 1px solid #f1f2f4;
  border-radius: 20px;
  padding: 20px;
}

.border-col p {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #001f2a;
}

.add-cart-btn {
  display: flex;
}

.add-cart-btn .model_btn_1 {
  background: #7df9ff;
  color: #001f2a;
  text-decoration: none;
}

.Available_units {
  width: 100%;
}

.Available_units p span {
  font-weight: 500;
  font-size: 15px;
  color: #3e545c;
  margin-bottom: 0;
  white-space: nowrap;
}

.img_time {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.model_VideoInjection .modal-header {
  padding: 0;
  border-bottom: 1px solid transparent;
}

.model_VideoInjection .modal-title {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  color: #001f2a;
}

.model_VideoInjection .form-label {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  margin-bottom: 0;
  padding-bottom: 5px;
}

.model_VideoInjection .form-control {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.model_VideoInjection .form-control::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.model_Upload_file .modal-header {
  padding: 0;
  border-bottom: 1px solid transparent;
}

.model_Upload_file .modal-title {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  color: #001f2a;
}

.model_Upload_file .form-label {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  margin-bottom: 0;
  padding-bottom: 5px;
}

.model_Upload_file .form-control {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
  width: 70%;
}

.model_Upload_file .form-control::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.model_flex {
  display: flex;
  gap: 30px;
  width: 100%;
}

.model_flex .file-drop-area {
  width: 30%;
}

.model_Upload_slide .modal-header {
  padding: 0;
  border-bottom: 1px solid transparent;
}

.model_Upload_slide .modal-title {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  color: #001f2a;
}

.model_Upload_slide .form-label {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  margin-bottom: 0;
  padding-bottom: 5px;
}

.model_Upload_slide.form-control {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.model_Upload_slide .form-control::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.model_flex_1 {
  display: flex;
  gap: 30px;
  width: 100%;
}

.first-column {
  width: 70%;
}

.second-column {
  width: 30%;
}

/* --------------------------------------------------------live page-end-------------------------------------------------------------- */
.language_select {
  display: flex;
  justify-content: center;
  width: 100%;
}

.language_select button {
  width: 100%;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #696e70 !important;
  text-align: start;
  display: flex;
  align-items: center;
  border: 1px solid #dbdfe3;
  border-radius: 3px;
  padding: 6px !important;
  cursor: not-allowed;
}

/* .flag-select__btn:before {
  display: none !important;
} */
/* .flag-select__btn:after,
.flag-select__btn[aria-expanded="true"]:after {
  position: relative;
  display: unset !important;
  margin: 0 !important;
  top: 12px;
} */
.flag-select__option {
  padding: 0 !important;
  margin: 0 !important;
}

.img_position {
  position: relative;
  display: flex;
}

.image_edit {
  position: absolute;
  bottom: 7px;
  right: 0;
}

.image_edit_1 {
  bottom: 2px;
  right: 35px;
}

.yourLinksTab .nav-item .nav-link {
  opacity: 0.5;
}

.yourLinksTab .nav-item .nav-link.active {
  opacity: 1;
}

.image-dp {
  object-fit: cover;
  border-radius: 50%;
}

.login_content.verify_content p {
  text-align: start;
}

.confrim_mail {
  min-height: calc(100vh - 186px);
  display: flex;
  align-items: center;
}

.label-content {
  align-items: start !important;
}

.label-content label h6 {
  text-align: start !important;
  padding-top: 0 !important;
}

.label-content label p {
  padding-left: 0 !important;
  text-align: start;
}

.form-check-input[type="radio"] {
  border: 1px solid;
}

/* ------------------------------------------------------Setting page---------------------------------------------------------- */
.blur_img {
  object-fit: cover;
  border-radius: 50%;
}

.Settings-page .profile-edit1 {
  position: relative;
  background: transparent !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Settings-page .profile-edit1 .edit-btn {
  position: absolute;
  right: 52px;
  bottom: 0;
  z-index: 1;
}

.setting-input .form-control {
  background: transparent;
  border: 1px solid transparent;
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.setting-input .form-control::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.setting-input .input-group-text {
  background: transparent;
  border: 1px solid transparent;
}

.setting-input .input-group {
  border: 1px solid #d1d4d7;
  border-radius: 5px;
}

.setting-input label {
  font-weight: 500;
  font-size: 15px;
  color: #3e545c;
}

.bg-blue {
  border-radius: 10px;
  padding: 20px;
  background: #eefeff;
  margin-left: 40px;
}

.bg-blue h6 {
  font-weight: 500;
  font-size: 15px;
  color: #3e545c;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.bg-blue h3 {
  font-weight: 700;
  font-size: 24px;
  color: #11777e;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.bg-blue p {
  font-weight: 700;
  font-size: 14px;
  color: #001f2a;
  margin-bottom: 0;
}

.setting-input.Contact_details {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Contact_details label {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
}

.Contact_details > div p {
  font-weight: 600;
  font-size: 16px;
  text-align: start;
  color: #11777e;
  margin-bottom: 0;
}

.Contact_details > div p span {
  margin-right: 10px;
}

.Contact_details_width {
  width: 23%;
}

.heading_Business {
  font-weight: 700;
  font-size: 20px;
  color: #001f2a;
  margin-bottom: 0;
  padding-bottom: 20px;
}

/* --------------------------------correction------------------------ */
.check-select {
  display: flex;
}

.check-select .form-check-label {
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  color: #001f2a;
  padding-left: 20px;
}

.check-select .form-check-input {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.check-select .form-check-input:checked[type="radio"] {
  /* background-image: url(./assets/images/check-circle.png) !important; */
}

.check-select .form-check-input:checked {
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: #29cf3a;
}

.check-select1 .form-check-input:checked {
  background-color: #29cf3a;
  border: 1px solid #29cf3a;
  border-radius: 50%;
}
.form-check-input[type="checkbox"] {
  border-radius: 50%;
}
.time-schedule .check-select p {
  padding-left: 0;
  padding-top: 10px;
}

.flex-tab-11 {
  display: flex;
  gap: 20px;
}

.scroll-bar {
  background: #00ffcc;
}

.scrollbar-track.vertical {
  background-color: #00ffcc !important;
  /* Track color */
}

.scrollbar-thumb.vertical {
  background-color: #002d3d !important;
  /* Thumb color */
}

.top-checked {
  display: none;
}

.edit_icon_btn {
  display: flex;
  gap: 10px;
  align-items: center;
}

.edit_icon_btn img {
  /* border-radius: 10px;
  padding: 10px 20px;
  background: #dbdfe3; */
}

/* --------------------------------------------fullconfig----------------------------------------------------- */
.img_position_basic {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.img_position_basic .image_edit {
  position: absolute;
  bottom: 7px;
  left: 44px;
  cursor: pointer;
}
.img_position_basic p {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #7e8385;
  margin-bottom: 0;
}
.head-field {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: #001f2a;
  margin-bottom: 15px;
}

.tab_flex1 {
  display: flex;
}

.tab-content-11-body {
  margin-top: 0px;
}

.tab_flex > div h6 {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #394346 !important;
}

.flex-tab-11 h6 {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #394346 !important;
}

.schedule-sec p {
  font-weight: 600;
  font-size: 14px;
  color: #11777e;
  margin-bottom: 0;
  white-space: wrap;
  border-radius: 10px;
  padding: 10px 20px;
  background: #e5fdff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.schedule-sec .input-group {
  border: 1px solid #dbdfe3;
  border-radius: 3px;
  padding: 10px 12px;
  align-items: center;
  position: relative;
  flex-wrap: nowrap;
  background: #fff;
}

/* .schedule-sec .input-group-text img {
  border: 1px solid transparent;
  background: transparent;
  padding-right: 10px !important;
  position: relative;
  width: 100%;
  max-width: 42px;
} */
.schedule-sec .input-group-text {
  border: 1px solid transparent;
  background: transparent;
  padding: 0 !important;
  position: relative;
  max-width: 42px;
  width: 100%;
}

.schedule-sec .rdt input {
  padding: 0 !important;
  height: auto !important;
  border: 1px solid transparent;
  background: #fff;
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.schedule-sec .react-datepicker__input-container input {
  border: 1px solid transparent;
  background: transparent;
  position: absolute;
  top: -18px;
  left: -54px;
  padding-left: 52px;
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.schedule-sec .date_time {
  border: 1px solid transparent;
  background: transparent;
  padding: 0;
  height: auto;
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.schedule-sec .form-select {
  border: 1px solid #dbdfe3;
  border-radius: 3px;
  padding: 10px 15px;
  padding-right: 35px;
  background: #fff;
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.schedule-sec .form-select:focus {
  border: 1px solid #dbdfe3 !important;
  border-radius: 3px;
}

.othersetting label {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
}

.othersetting .form-control::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.othersetting .form-control {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.othersetting .file-drop-area {
  width: 100%;
}

.othersetting p {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #7e8385;
  margin-bottom: 0;
  padding-top: 5px;
}
.othersetting_sub {
  background: #f4f8fa;
  border-radius: 3px;
  padding: 16px;
}
.othersetting_sub .form-check-input[type="radio"] {
  border-radius: 10px !important;
  background-color: #4ed2d9;
  border: 1px solid #4ed2d9;
}
.othersetting_sub .form-check-input:checked {
  background-color: #4ed2d9 !important;
  border: 1px solid #4ed2d9 !important;
  border-radius: 10px !important;
}
.othersetting_sub .form-check-input[type="checkbox"] {
  border-radius: 10px !important;
}
.host-chat {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.host-chat h4 {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  margin-bottom: 0;
  white-space: nowrap;
}

.host-head {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}

.incomplete-notify {
  border-radius: 4px;
  padding: 4px;
  background: rgba(255, 58, 49, 0.1);
  display: flex;
  gap: 4px;
  align-items: center;
  margin: 0px;
}

.incomplete-notify p {
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
  color: #ff3a31;
}

.form-control::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.Auto_subscription .nav-pills {
  min-width: 78%;
}

.nav_link1 {
  width: 50%;
}

.tab_flex > div {
  width: 50% !important;
}

.tab-content-11-body label {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #001f2a;
}

.radio-check h6 {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a !important;
  margin-bottom: 0;
}

.radio-check p {
  font-weight: 600;
  font-size: 16px;
  color: #001f2a !important;
  margin-bottom: 0;
  padding-top: 10px;
}

.radio-check .form-check-input:checked {
  background-color: #11777e !important;
  border-color: #11777e !important;
}

.radio-check.radio-disable p {
  opacity: 0.5;
}

.radio-check.radio-disable h6 {
  opacity: 0.5;
}

.radio-check.radio-disable p {
  opacity: 0.5;
}

.badge-radio {
  display: flex;
  align-items: center;
  gap: 20px;
}

.vaffix-schedule.checked {
  border: 1px solid #dbdfe3;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}

.vaffix-radio-check {
  border: 1px solid transparent;
  border-radius: 10px;
  /* padding: 20px; */
  background-color: transparent;
}
.vaffix-radio-check h6 {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #394346;
}

.vaffix-radio-check p {
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  color: #696e70;
}

/* .vaffix-radio-check.checked {
  border: 1px solid #dbdfe3;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
} */

.image_edit_change {
  right: 0 !important;
  left: 93px !important;
}

.basic-margin {
  margin-top: 0 !important;
}

.edit-close-btn {
  display: flex;
  align-items: center;
  gap: 20px;
}

.edit-close-btn img {
  width: 100%;
  min-width: 24px;
}

/* .edit-close-btn a {
  border: 1px solid #d1d4d7;
  border-radius: 100px;
  box-shadow: 1px 1px 2px 0 rgba(209, 212, 215, 0.5),
    2px 2px 7px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 10px;
} */
.select_box_1 .form-select::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

/* .pre_notification .pre_notification_list:last-child::after {
  display: none;
} */
.select-text {
  display: flex;
  gap: 20px;
}

.tab_content_1_sub.registeractive {
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border: 1px solid #dbdfe3;
  border-radius: 10px;
  padding: 20px;
}

.tab_content_1_sub2.registeractive {
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border: 1px solid #dbdfe3;
  border-radius: 10px;
  padding: 20px;
}

.select-text .form-select {
  width: 100%;
  max-width: 300px;
  background: #fafafa;
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.select-text .form-control {
  width: 100%;
  max-width: 100px;
}

.select-text1 .input-group-text {
  background: transparent;
  border: 1px solid transparent;
}

.select-text1 .form-control {
  background: transparent;
  border: 1px solid transparent;
  background: #fafafa;
  font-weight: 500;
  font-size: 14px;
  color: #7e868d;
}

.select-text1 .input-group {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background: #fff;
}

.text-align1 {
  text-align: end;
  font-size: 14px;
}

.text-align2 {
  font-size: 14px;
  line-height: 24px;
}

.accordionedit-btn:hover {
  color: #11777e;
  text-decoration: none;
  background: #fff;
  border: 1px solid #11777e;
}

.modal_button_one:active {
  background: #001f2a !important;
}

.template_customize .modal-dialog {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 !important;
  min-height: 100vh !important;
}

.template_customize.modal {
  margin: 0 !important;
}

.template_customize .modal-content {
  border-radius: 0 !important;
  padding: 0 !important;
  min-height: 100vh;
}

.template_customize .modal-body {
  padding: 0 !important;
}

.finishpage {
  text-align: center;
}

.finishpage h2 {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #11777e;
  margin-bottom: 0;
  margin-top: 10px;
}

.finishpage p {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #3e545c;
  margin-bottom: 0;
}

.finish_model .modal-header {
  border-bottom: 1px solid transparent;
}

.registration_radio .registration_body.select {
  background: #ffffff !important;
  border: 1px solid #4ed2d9;
  position: relative;
}

.registration_radio .registration_body .registration_sec_image {
  position: relative;
}

.registration_radio .registration_body.select .registration_sec_image::after {
  content: "";
  position: absolute;
  background: #001f2a6e url(./assets/images/overlay.png) no-repeat center center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.template_customize .modal-dialog {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 !important;
  min-height: 100vh !important;
}

.template_customize.modal {
  margin: 0 !important;
}

.template_customize .modal-content {
  border-radius: 0 !important;
  padding: 0 !important;
  min-height: 100vh;
}

.template_customize .modal-body {
  padding: 0 !important;
}

.temp_config.nav-tabs .nav-link {
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  color: #fff;
  background: #eff3f7;
  border-radius: 3px;
  padding: 8px 12px;
  margin-right: 12px;
}

.temp_config.nav-tabs .nav-link.active {
  background: #001f2a;
}

.temp_config {
  border-bottom: 1px solid transparent !important;
}

.temp-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.limit_sec .form-control {
  border: 1px solid transparent !important;
  background: transparent;
  padding: 0;
  height: auto !important;
}

.limit_sec {
  padding: 7px 15px !important;
}

.language_select .flag-select__options {
  width: 100%;
}

.no-meeting {
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  color: #394346;
  text-align: center;
}

.select-search-container {
  border: 1px solid transparent !important;
  width: auto !important;
}

.select-search-value {
  border: 1px solid transparent !important;
}

.select-search-options {
  padding-left: 0 !important;
}

.select-search-select {
  border: 1px solid #7e868d !important;
  max-height: 260px !important;
}

.select-search-container:not(.select-search-is-multiple) .select-search-select {
  z-index: 99;
}

.select-search-input {
  border: 1px solid #dbdfe3 !important;
  border-radius: 3px !important;
  padding: 10px 15px !important;
  padding-right: 35px !important;
  background: #fff !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #7e868d !important;
  height: 46px !important;
}

.select-search-row .select-search-option {
  background: #fafafa !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #7e868d !important;
}

.select-search-row button:hover {
  background: #e4e6e79e !important;
}

.form-select:focus {
  box-shadow: none;
  border-color: #dee2e6;
}

.hover-notallow p {
  cursor: not-allowed;
}

.hover-notallow a {
  cursor: not-allowed;
}
.hover-notallow a {
  color: #11777e;
  font-size: 20px;
}
.hover-notallow .trash {
  color: #ff3a31;
  font-size: 20px;
}
.hover-notallow .input-group .form-control {
  cursor: not-allowed;
}

.hover-notallow .input-group {
  cursor: not-allowed;
}

.hover-notallow .form-select {
  cursor: not-allowed;
}

.hover-allow {
  cursor: pointer !important;
}

.temp_preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #7df9ff;
  padding: 10px 40px;
}

.temp_preview .primary_btn {
  text-decoration: none;
}

.error_input {
  position: relative;
  width: 100%;
}

.error_msg {
  /* position: absolute; */
  /*bottom: -26px;*/
}

.error_msg span {
  font-size: 14px;
}

.rdtPicker {
  background: #e5fdff !important;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1) !important;
  left: -7px;
  width: 100%;
  top: 43px;
  max-width: unset;
}

.rdt {
  position: initial !important;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #003a4e !important;
  color: #fff !important;
}

.rdtPicker td {
  color: #001f2a !important;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background: #003a4e !important;
  color: #fff !important;
}

.rdtPicker thead tr:first-of-type th:hover {
  color: #fff !important;
  background: #003a4e !important;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  opacity: 0.5 !important;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  color: #fff !important;
  background: #003a4e !important;
}

.rdtCounter .rdtBtn:first-child {
  font-size: 0;
  background: url(./assets/images/arrow-up.png) no-repeat;
  position: relative;
  left: 15px;
  top: 24px;
}

.rdtCounter .rdtBtn:last-child {
  font-size: 0;
  background: url(./assets/images/arrow-up.png) no-repeat;
  transform: rotate(180deg);
  position: relative;
  left: -13px;
  top: -19px;
}

.rdtCounter .rdtBtn:hover {
  background: url(./assets/images/arrow-up.png) no-repeat !important;
}

.rdtCounter .rdtCount {
  font-size: 16px !important;
}

.schedule-sec .rdtPicker {
  top: 53px !important;
  min-width: auto !important;
  left: 0 !important;
}

.inconplete_config {
  /* height: calc(100% - 67px); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.register_model .instant-meeting-head {
  width: 100% !important;
}

.register_model .modal-content {
  background: url(./assets/images/registergif.gif) no-repeat;
  background-size: cover;
}

.formcheck_register .form-check-input {
  margin-left: -19px;
}

/* inner_home */
.inner_home_shadow {
  background: #fff;
  border: 1px solid #dbdfe3;
  border-radius: 6px;
  margin-bottom: 30px;
}

.dashbord-right-header.inner_home_dashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #dbdfe3;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.inner_home_body {
  border-radius: 0px 0px 6px 6px;
  padding: 20px;
}

.inner_home_content {
  display: flex;
  align-items: center;
  gap: 15px;
  border: 1px solid #dedfe0;
  border-radius: 10px;
  padding: 20px;
  background: #fff;
}

.inner_home_content h5 {
  font-weight: 500;
  font-size: 30px;
  font-family: "Manrope", sans-serif;
  color: #001f2a;
}

.inner_home_content p {
  font-weight: 500;
  font-size: 16px;
  color: #3c4043;
  margin-bottom: 0px;
}

.inner_support_ticket .form-label {
  font-weight: 400;
  font-size: 14px;
  color: #394346;
}
.inner_support_ticket .form-control {
  border: 1px solid #dbdfe3 !important;
  border-radius: 3px !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #696e70 !important;
  padding: 10px;
}
.inner_support_ticket textarea.form-control {
  padding: 12px !important;
}
.inner_support_ticket .form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none !important;
}

.inner_support_ticket .form-select {
  border: 1px solid #dbdfe3;
  border-radius: 10px;
  padding: 14px;
}

.inner_support_ticket .file-drop-area {
  width: 100%;
  padding: 12px;
}

.inner_support_ticket .primary_btn {
  width: 100%;
}

.inner_support_ticket p {
  font-weight: 400;
  font-size: 14px;
  color: #696e70;
}

.inner_support_ticket .inner_tab_content {
  border: 1px solid #dbdfe3;
  border-radius: 6px;
  padding: 20px;
  background: #fff;
}

.inner_tab_content {
  /* display: flex; */
  background: #faf3da;
  border-radius: 10px;
  padding: 8px;
  gap: 10px;
  margin-bottom: 20px;
  height: 100%;
}

.inner_tab_content .border_tab button {
  /* flex: 1; */
  border-radius: 6x;
  padding: 10px 12px;
  font-weight: 500;
  font-size: 14px;
  color: #001f2a;
  background: #eff3f7 !important;
  border: none;
  width: 50%;
}

.inner_tab_content .border_tab button.active,
.inner_tab_content .border_tab button:hover {
  font-weight: 500;
  font-size: 14px;
  background: #7df9ff !important;
  color: #001f2a !important;
}

.inner_tab_content .border_tab {
  display: flex;
  gap: 6px;
}

.inner_tab_content .ticket_panel_div h4 {
  font-weight: 500;
  font-size: 14px;
  color: #001f2a;
}

.inner_tab_content .ticket_panel_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.inner_tab_content .ticket_panel_details label {
  font-weight: 400;
  font-size: 13px;
  color: #6a6a6a;
}

.inner_tab_content .ticket_panel_details span {
  font-weight: 600;
  color: #001f2a;
}

.inner_tab_content .ticket_panel_bottom {
  display: flex;
  gap: 8px;
}

.inner_tab_content .ticket_panel_div {
  border: 1px solid #dedfe0;
  border-radius: 10px;
  padding: 16px;
  background: transparent;
  transition: 0.2s all;
  margin-bottom: 15px;
}

.inner_tab_content .ticket_panel_div:hover,
.inner_tab_content .ticket_panel_div.active {
  border: 1px solid #e9edf1;
  border-radius: 3px;
  background: #f3f7f9;
}

.inner_tab_content .ticket_panel {
  height: 500px;
  overflow-y: auto;
  padding-right: 5px;
}

.inner_tab_content .ticket_details h4 {
  font-weight: 500;
  font-size: 14px;
  color: #001f2a;
}

.support_empty {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.ticket_details h4 {
  font-weight: 600;
  font-size: 16px;
  color: #6a6a6a;
}

.ticket_details_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.ticket_details_flex label {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #394346;
}

.ticket_details_flex span {
  font-weight: 500;
  color: #394346;
}

/* .msg_box_content {
  border-radius: 10px 0 10px 10px;
  padding: 16px;
  background: #eefeff;
}
.msg_box_content p {
  font-weight: 500;
  font-size: 15px;
  line-height: 160%;
  color: #191d23;
}
.msg_box_div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.msg_box_panel {
  margin-top: 20px;
}



.msg_box_panel h6 {
  font-weight: 600;
  font-size: 13px;
  color: #6a6a6a;
  text-align: right;
  margin-right: 50px;
  margin-top: 5px;
}
.admin_msg_box_panel h6 {
  text-align: left;
  margin-left: 50px;
  margin-right: 0;
}
.admin_msg_box_panel .msg_box_content {
  border: 1px solid #ecedee;
  background: #fafafa;
  border-radius: 0 10px 10px 10px;
  text-align: left;
}

.msg_box_panel .msg_box_content {
  text-align: right;
 
} */

.msg_box_content {
  border-radius: 10px 0 10px 10px;
  padding: 16px;
  background: #eefeff;
  font-weight: 500;
  font-size: 15px;
  line-height: 160%;
  color: #191d23;
}

.msg_box_div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.msg_box_panel {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* Default to right alignment */
  /* text-align: right; */
}

/* User message styling - Align to the right */
.user_msg_box_panel {
  justify-content: flex-end;
  text-align: right;
}

.user_msg_box_panel .msg_box_content {
  text-align: right;
  border: 1px solid #e9edf1;
  border-radius: 3px;
  background: #f3f7f9;
  width: 100%;
}

.user_msg_box_panel h6 {
  margin-right: 50px !important;
}

/* Admin message styling - Align to the left */
.admin_msg_box_panel {
  justify-content: flex-start;
}

.admin_msg_box_panel .msg_box_content {
  text-align: left;
  border: 1px solid #e9edf1;
  border-radius: 3px;
  /* background: #f3f7f9; */
  width: 100%;
}

.user_msg_box_panel .msg_box_div {
  flex-direction: row-reverse !important;
}

/* .admin_msg_box_panel .msg_box_div {
  flex-direction: row-reverse !important;
} */
/* .admin_msg_box_panel h6 {
  text-align: left;
  margin-left: 50px;
} */

.msg_box_panel h6 {
  font-weight: 400;
  font-size: 12px;
  color: #7e8385;
  margin-top: 5px;
  margin-left: 50px;
}
.admin_msg_box_panel h6 {
  margin-right: 50px !important;
}
.disabled-link {
  pointer-events: none;
  cursor: not-allowed;
  text-decoration: none;
}

.msg_box_wrapper {
  height: 420px;
  /* height: 121px; */
  overflow-y: auto;
  padding-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.support_txtbox .form-control {
  border: 1px solid transparent !important;
  height: auto !important;
}

.support_txtbox .input-group-text {
  border: 1px solid transparent !important;
  background: transparent;
  padding: 10px;
}

.support_txtbox.input-group button {
  width: auto !important;
}

.support_txtbox.input-group button:hover {
  background: transparent !important;
}

.support_txtbox.input-group button:active {
  background: transparent !important;
}

.support_txtbox {
  border: 1px solid #cfcbc9 !important;
  border-radius: 3px;
  /* padding: 16px; */
  box-shadow: none !important;
  height: 100%;
}

.support_txtbox_btngrp {
  position: absolute;
  bottom: 100px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.support_upload_btn button {
  background: #d6ffff !important;
  border: 1px solid #d6ffff !important;
  border-radius: 10px;
  padding: 4px;
  width: 100%;
}

.inner_tab_content .send_btn {
  /* flex: 1; */
  border-radius: 100px;
  padding: 12px;
  font-weight: 600;
  font-size: 16px;
  color: #001f2a;
  background: transparent;
  border: none;
  width: 50%;
}

.inner_tab_content .send_btn:hover {
  background: transparent !important;
  color: #001f2a !important;
}

.uploaded_file span {
  font-weight: 600;
  font-size: 12px;
  color: #7e868d;
}

.support_upload_btn {
  position: relative;
  width: 100%;
}

.support_upload_btn input.form-control[type="file"] {
  opacity: 0;
  position: absolute;
  z-index: 10;
}

.inner_fields {
  display: flex;
  align-items: center;
  gap: 20px;
}

.inner_settings_shadow {
  padding: 10px 20px;
}

.inner_settings_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inner_settings_head p {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #001f2a;
  margin-bottom: 0px;
}

.inner_fields .input-group {
  border: 1px solid #dbdfe3;
  background: #fff;
  border-radius: 3px;
  padding: 12px;
}
.inner_fields1 {
  gap: unset !important;
}

.inner_fields .form-control {
  border: none;
  padding: 0;
  height: auto;
  font-weight: 400;
  font-size: 13px;
  color: #696e70;
}
.inner_fields .form-control::placeholder {
  font-weight: 400;
  font-size: 13px;
  color: #696e70;
}
.inner_fields .input-group-text {
  border: none;
  padding: 0;
  background-color: transparent !important;
}

.inner_fields .form-control:focus {
  border: none;
  box-shadow: none;
}

.inner_settings_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  padding: 16px;
  background: #f3f7f9;
  border: 1px solid #e9edf1;
}

.inner_settings_details p {
  font-weight: 500;
  font-size: 14px;
  color: #394346;
  margin-bottom: 0px;
}

.inner_settings_details span {
  font-weight: 600;
  font-size: 16px;
  color: #11777e;
  margin-bottom: 0px;
}

/* .inner_settings_details .primary_btn {
  background: #7df9ff;
  border-radius: 3px;
  padding: 4px 8px;
} */
.inner_settings_details .primary_btn:hover {
  background: #001f2a;
}
.settings_shadow {
  border: 1px solid #dbdfe3;
  border-radius: 20px;
  padding: 20px 0px;
  box-shadow: 2px 2px 4px 0 rgba(209, 212, 215, 0.5),
    4px 4px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  height: 100%;
}

.settings_shadow {
  margin-left: 325px;
}

.settings_display {
  width: 100%;
  height: 100%;
  gap: 20px;
}

.settings_display .profile_info .form-label {
  font-weight: 400;
  font-size: 14px;
  color: #394346;
}

.settings_display .form-control {
  width: 60%;
  font-weight: 400;
  font-size: 13px;
  color: #696e70;
  border: 1px solid #dbdfe3;
  border-radius: 3px;
  height: 39px;
}
.settings_display .form-control::placeholder {
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #696e70 !important;
}
/* .settings_display .primary_btn {
  margin-left: 10px;
} */

.settings_display .primary_btn:active {
  background-color: #001221 !important;
  border-color: #001221 !important;
}

.reset_code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.reset_code1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.reset_code div:first-child {
  display: flex;
  align-items: center;
  gap: 8px;
}
.reset_code h2 {
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  color: #001f2a;
  margin-bottom: 0;
  text-decoration: none;
}
.inner_profile_settings h4 {
  font-weight: 500;
  font-size: 13px;
  color: #001f2a;
  background: #e9edf1;
  text-align: justify;
  border-radius: 3px;
  padding: 12px;
  margin: 12px 0;
  width: 60%;
  word-break: break-all;
}
.inner_settings_alignment {
  display: flex;
  align-items: center;
  gap: 20px;
}

.inner_profile_settings {
  border-radius: 6px;
  padding: 16px;
  background: #f3f7f9;
  height: 100%;
  border: 1px solid #e9edf1;
  margin: 0;
}

.inner_profile_settings h5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 154%;
  color: #696e70;
  margin-bottom: 12px;
}

/* .inner_profile_settings button {
  font-weight: 700;
  font-size: 14px;
  color: #001f2a;
  margin-bottom: 0px;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0;
} */

.reset_code p {
  text-align: start;
}

.inner_select_box {
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 4px;
  background: #fff;
  border: 1px solid #dbdfe3;
  border-radius: 10px;
  margin: 20px;
}

.inner_active_body {
  margin: 20px;
}

.inner_active_body .table {
  /* border: 1px solid #eff3f7; */
  border-top-left-radius: 10px !important;
}

.inner_active_body tr {
  /* border: 1px solid #eff3f7; */

  border-radius: 10px;
  border-top-left-radius: 10px !important;
}

.inner_active_body td {
  padding: 16px 20px !important;
  font-weight: 500;
  font-size: 14px;
  color: #696e70;
  white-space: nowrap;
}

.inner_active_body td:first-child {
  border-left: 1px solid #eff3f7;
}

.inner_active_body td:last-child {
  border-right: 1px solid #eff3f7;
}

.table thead th {
  border-bottom: 1px solid transparent !important;
  padding: 12px 20px !important;
  background-color: #eff3f7;
  border-top: none !important;
  /* border: none !important; */
  font-size: 13px;
  font-weight: 500;
}

.table thead th:first-child {
  border-top-left-radius: 6px !important;
  /* border-left: 1px solid #eff3f7 ; */
}

.table thead th:first-child {
  border-top-left-radius: 6px !important;
}

.table thead th:last-child {
  border-top-right-radius: 6px;
}

.inner_select_box .form-select {
  font-size: 15px;
  font-weight: 400;
  color: #001f2a;
}

.web_display {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.forgetpassword1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.forgetpassword1 label {
  font-weight: 500;
  font-size: 14px;
  color: #001f2a;
}

.forgetpassword1 a {
  font-weight: 500;
  font-size: 15px;
  color: #001f2a;
  text-decoration: none;
}

.form-check-input:checked {
  background-color: #001f2a;
  border-color: #001f2a;
}
.Webinar-check {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.inner_support_ticket .filterSessions {
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #696e70 !important;
}
.pagination {
  gap: 5px;
  margin-bottom: 0;
}
.items-per-page-selector {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: end;
  margin-bottom: 20px;
}
.page-item {
  border-radius: 3px;
  font-size: 14px;
}
.page-link {
  font-size: 14px;
  padding: 6px 12px;
  background: #fff !important;
  border: 1px solid #dbdfe3 !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #696e70 !important;
}
.page-item.active .page-link {
  background-color: #7df9ff !important;
  border-color: #7df9ff !important;
  color: #001f2a !important;
}
.page-link:focus {
  box-shadow: none;
}
.page-link span {
  font-size: 14px;
}
.page-item:first-child .page-link {
  border-radius: 3px !important;
}
.page-item:last-child .page-link {
  border-radius: 3px;
}
.items-per-page-selector label {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #394346;
  margin-bottom: 0;
}
.items-per-page-selector select {
  border: 1px solid #dbdfe3;
  border-radius: 3px;
  background: #fff;
  font-weight: 400;
  font-size: 13px;
  color: #696e70;
  padding: 5px;
}
.items-per-page-selector select:active {
  border: 1px solid #dbdfe3 !important;
}
.pagination-flex label {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #394346;
  margin-bottom: 0;
}
.pagination-flex select {
  border: 1px solid #dbdfe3;
  border-radius: 3px;
  background: #fff;
  font-weight: 400;
  font-size: 13px;
  color: #696e70;
  padding: 5px;
}
.pagination-flex select:active {
  border: 1px solid #dbdfe3 !important;
}
.model-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.pagination-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pagination-flex label {
  margin-bottom: 0;
}
.pagination-flex .items-per-page-selector {
  margin-bottom: 0;
}
.email-model .form-control {
  border-radius: 3px;
}
.email-model .form-label {
  font-weight: 400;
  font-size: 14px;
  color: #394346;
}
.blue_shade {
  box-shadow: 0 4px 9px 0 rgba(51, 241, 255, 0.1),
    0 16px 16px 0 rgba(51, 241, 255, 0.09),
    0 37px 22px 0 rgba(51, 241, 255, 0.05),
    0 66px 26px 0 rgba(51, 241, 255, 0.01), 0 103px 29px 0 rgba(51, 241, 255, 0);
  background: linear-gradient(180deg, #fff 0%, #d1fdff 100%);
  border: 1px solid rgba(78, 210, 217, 0.5);
}
.blue_shade .primary_btn.deactive {
  opacity: 0.5;
  cursor: not-allowed;
}
.blue_shade .primary_btn.deactive:hover {
  background: #7df9ff;
  color: #001f2a;
}
.blue_flex_sub {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blue_flex_sub div {
  display: flex;
  align-items: center;
  gap: 20px;
}
.blue_box {
  display: flex;
  align-items: center;
  background: #cdf4f6;
  padding: 4px 8px;
  border-radius: 3px;
}
.hrline {
  border-top: 1px solid #cdf4f6;
  padding: 10px 0;
  margin-top: 10px;
}
.hrline2 {
  border-top: 1px solid #eff3f7;
}
.blue_flex p {
  font-weight: 400;
  font-size: 14px;
  color: #394346;
}
.blue_flex p span {
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  color: #001f2a;
}
.blue_flex_sub p {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #394346;
  margin-bottom: 0;
}
.blue_flex_sub p span {
  font-weight: 500;
  font-size: 16px;
  color: #001f2a;
}
.Subscription-right {
  background: url(./assets/images/bg-subscription.png) no-repeat center;
  background-size: 100%;
}

.subscription-tab {
  border-bottom: 0;
  gap: 20px;
  justify-content: center;
  margin: 50px auto 30px;
}
.nav-tabs.subscription-tab .nav-link {
  background: transparent;
  border: 1px solid #dbdfe3 !important;
  color: #001f2a !important;
  border-radius: 6px;
  width: 250px;
  height: 100%;
  padding: 10px 0;
  font-weight: 500;
  font-size: 16px;
}
.nav-tabs.subscription-tab .nav-link span {
  font-weight: 500;
  font-size: 12px;
}
.nav-tabs.subscription-tab .nav-link.active {
  border: 1px solid #4ed2d9 !important;
  background: #fff;
}
.Subscription-right .head h2 {
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  color: #001f2a;
}
.Subscription-right .head p {
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: #394346;
}
.subscription-box {
  background: #fff;
  border: 1px solid rgba(0, 31, 42, 0.12);
  border-radius: 10px;
  padding: 36px 20px;
  text-align: center;
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.subscription-box-batch {
  box-shadow: 0 12px 25px 0 rgba(1, 30, 41, 0.1),
    0 46px 46px 0 rgba(1, 30, 41, 0.09), 0 104px 63px 0 rgba(1, 30, 41, 0.05),
    0 185px 74px 0 rgba(1, 30, 41, 0.01), 0 290px 81px 0 rgba(1, 30, 41, 0);
  background: radial-gradient(
    108.93% 100% at 37.98% 0%,
    rgb(0, 14, 19) 0%,
    rgb(0, 14, 19) 73.66132736206055%,
    rgb(17, 119, 126) 100%
  );
  border: none;
  position: relative;
}
.subscription-box h4 {
  font-weight: 500;
  font-size: 24px;
  color: #001f2a;
  margin-bottom: 25px;
}
.subscription-box-batch h4 {
  color: #7df9ff;
}
.subscription-box p {
  font-weight: 400;
  font-size: 14px;
  line-height: 171%;
  text-align: center;
  color: #394346;
  margin-bottom: 15px;
}
.subscription-box-batch p {
  color: #fff;
}
.subscription-box .content1 {
  border-bottom: 1px solid rgba(0, 31, 42, 0.08);
}
.subscription-box.subscription-box-batch .content1 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.subscription-box .content2 {
  border-bottom: 1px solid rgba(0, 31, 42, 0.08);

  /* padding: 24px 0px; */
}
.subscription-box .content2 ul {
  list-style-type: none;
  text-align: start !important;
}
.subscription-box .content2 ul li {
  position: relative;
  font-weight: 400;
  font-size: 14px;
  color: #394346;
  margin-bottom: 10px;
  /* line-height: 12px !important; */
}
.subscription-box.subscription-box-batch .content2 ul li {
  color: #fff;
}
.subscription-box .content2 ul li::before {
  content: "";
  position: absolute;
  background: url(./assets/images/accordion-config.png);
  width: 16px;
  height: 16px;
  left: -29px;
  top: 4px;
}
.subscription-box .content2 ul li.disable_li {
  opacity: 0.6;
  cursor: not-allowed;
}
.subscription-box .content2 ul li.disable_li::before {
  content: "";
  position: absolute;
  background: url(./assets/images/close-circle.png);
  width: 21px;
  height: 20px;
  left: -29px;
  top: 1px;
}
.subscription-box.subscription-box-batch .content2 ul li.disable_li::before {
}
.subscription-box-batch .content2 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  /* border-top: 1px solid rgba(255, 255, 255, 0.1); */
}
.subscription-box h1 {
  font-weight: 700;
  font-size: 36px;
  color: #001f2a;
  margin-bottom: 0;
}
.subscription-box h1 span {
  font-weight: 700;
  font-size: 20px;
}
.subscription-box-batch h1 {
  color: #fff;
}
.subscription-box h6 {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  color: #394346;
  background: rgba(57, 67, 70, 0.05);
  border-radius: 3px;
  padding: 6px 12px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.subscription-box-batch h6 {
  color: #7df9ff;
  background: rgba(125, 249, 255, 0.05);
}
.subscription-box button {
  width: 100%;
  margin: 0px 0 20px;
}
.subscription-box-batch .batch {
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  color: #bc5912;
  background: #fff4d9;
  border-radius: 43px;
  padding: 6px 12px;
  position: absolute;
  top: -16px;
  left: 0;
  width: max-content;
  right: 0;
  margin: auto;
}

.chartFilter {
  display: flex;
  margin-bottom: 20px;
  gap: 20px;
}
.chartFilter .datapick {
  flex: 1;
}
.chartFilter .react-datepicker-wrapper {
  width: 100%;
  min-width: 100%;
}
.chartFilter .datapick_head {
  min-width: 100%;
}
.innerNavJoin .primary_btn {
  border: 1px solid #7df9ff;
}
@media (max-width: 1599px) {
  .configration_sec {
    flex-direction: column;
  }

  .tab_content {
    width: 100%;
    padding-top: 20px;
  }

  .config_form {
    width: 100%;
  }

  .configuration_tab .nav-pills {
    width: 70%;
    height: 418px;
  }
}

@media (max-width: 1399px) {
  .subscription-box h6 {
    font-size: 14px;
  }
  .user_content {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .name-content {
    flex-wrap: wrap;
  }

  .setting-profile-detail {
    margin-left: 25px;
  }

  .registration_nav_content.active {
    background: transparent;
    border: 1px solid transparent;
    flex-direction: column;
  }

  .registration_nav_content.active .top-head-img {
    border: 1px solid #11777e;
    padding: 8px;
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
  }

  .registration_nav_content.active .top-checked {
    position: absolute;
    top: -7px;
    right: -7px;
    z-index: 9;
  }

  .registration_nav_content {
    background: transparent;
    border: 1px solid transparent;
    flex-direction: column;
  }

  .registration_nav_content .top-head-img {
    border: 1px solid #11777e;
    padding: 8px;
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
    background: #fff;
  }

  .registration_list::before {
    top: 29px;
  }

  .registration_nav_sec .registration_list {
    gap: 20px;
  }

  .registration_list li::before {
    display: none;
  }

  /* .top-checked1 {
    display: none;
  } */

  .top-checked {
    display: block !important;
  }

  .webinar-width2 .img_position_basic .image_edit {
    left: 77px;
  }
  .button-end {
    text-align: center !important;
    margin-top: 10px;
  }
}

@media (max-width: 1299px) {
  .inner_home_content p {
    font-size: 15px !important;
  }

  .inner_home_content {
    margin-top: 20px !important;
  }
}

@media (max-width: 1199px) {
  .subscription-box {
    padding: 30px 20px;
  }
  .subscription-box h1 {
    font-size: 40px;
  }
  .subscription-box h6 {
    font-size: 14px;
  }
  .configuration_webinar .webinar-setting-sub-head {
    flex-direction: column;
  }
  .subscription-box .content2 {
    display: flex;
    justify-content: center;
  }
  .configuration_webinar .webinar-width {
    width: 100%;
  }

  .configuration_webinar .webinar-width1 {
    width: 100%;
  }

  .profile-change {
    justify-content: start;
  }

  .name-content {
    justify-content: center;
  }

  .webinar-setting-sub p {
    text-align: center;
  }

  .webinar-setting-sub .profile_img_1 {
    margin: auto;
  }

  .configuration_tab .nav-pills {
    height: 100% !important;
  }

  /* .polls-add-btn {
    width: unset;
  }
  .polls-Add {
    flex-wrap: wrap;
  } */
  .poll-head {
    display: flex;
    gap: 30px;
  }

  .polls-Add {
    flex-wrap: wrap;
    width: 50%;
  }

  .polls-qa {
    flex-direction: column;
    gap: 20px;
  }

  .poll-question {
    width: 100% !important;
  }

  .poll-answer {
    width: 100% !important;
  }

  .tab_content_11 {
    flex-direction: column;
  }

  .tab_flex {
    width: 100% !important;
  }

  .tab-img {
    margin: auto;
  }

  .newSission .input-group {
    max-width: 160px;
    width: 100%;
  }

  .newSission .error_input {
    max-width: 160px;
    width: 100%;
  }

  .newSission .form-select {
    max-width: 160px;
    width: 100%;
  }

  .newSission {
    flex-wrap: wrap !important;
  }

  .host-head {
    margin-top: 20px;
  }

  .host-chat h4 {
    white-space: nowrap;
  }
  .settings_display .inner_profile_settings {
    padding: 12px !important;
  }
  .profile_info {
    padding: 0 !important;
  }

  /* .settings_display .primary_btn {
    width: 50%;
  } */

  .support_inner_nav .nav-sidebar {
    display: none;
  }

  .support_inner_nav .dashbord-right {
    margin-left: 0px !important;
    width: 100% !important;
  }

  .webinar-width2 .img_position_basic .image_edit {
    left: 67px;
  }
  .dashbordrightbody-content div {
    flex-direction: column;
    align-items: baseline;
  }
}
@media (max-width: 991px) {
  .settings_display .form-control {
    width: 100%;
  }
  .inner_profile_settings h4 {
    width: 100%;
    text-align: center;
    margin-top: 15px;
  }
  .reset_code1 button {
    margin: auto;
  }
  .inner_profile_settings h5 {
    margin-top: 12px;
  }
  .reset_code1 {
    flex-direction: column;
    /* align-items: start; */
    margin-top: 15px;
  }
  .Subscription-right .head {
    padding-top: 50px;
  }
  .tab_content {
    padding-top: 20px;
  }
  .button-end {
    text-align: center !important;
  }
  .profile-change {
    padding-top: 0px;
  }

  .config_form {
    height: auto;
  }

  .mail-input {
    padding-top: 10px;
  }

  .configuration_webinar {
    flex-direction: column;
  }

  .Settings-page {
    margin-top: 70px;
    margin-bottom: 100px;
  }

  .inner_tab_content {
    /* margin-top: 20px !important; */
  }

  .tab_button .nav-link {
    font-size: 12px;
  }

  .tab_button {
    border: 1px solid transparent;
  }

  .nav-tabs .nav-link.active {
    padding: 10px;
  }

  .tab_button .nav-item {
    padding: 0;
  }

  .settings_display .img_position_basic .image_edit {
    right: 11px;
  }

  .inner_support_ticket .inner_tab_content {
    height: auto !important;
  }

  .home_slider .slick-next {
    left: unset;
    right: 35px;
    top: -74px;
    color: #e96230;
  }

  .home_slider .slick-prev {
    left: unset;
    right: 100px;
    top: -83px;
    color: #e96230;
  }

  .slider_h3tag {
    font-size: 36px;
  }

  .lock_img {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .home_slider .slider-container {
    border-radius: 30px !important;
  }
  .dashbordrightbody-content {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .select_box_1 .form-select {
    width: 60%;
  }

  .bg-blue {
    margin-top: 20px;
    margin-left: 0 !important;
    text-align: center;
  }

  .setting-input.Contact_details {
    flex-wrap: wrap;
  }

  .Contact_details_width {
    width: 100% !important;
  }

  .time-schedule .check-select p {
    display: none;
  }

  .inner_select_box {
    flex-wrap: wrap;
  }

  .inner_home_shadow {
    margin-bottom: 18px !important;
  }

  .dashbordrightbody-content {
    flex-direction: column;
  }
  .dashbordrightbody-content div {
    flex-direction: column;
    align-items: baseline;
  }
  .settings_display {
    width: 100%;
  }

  .settings_display .img_position_basic .image_edit {
    right: 38px;
  }

  .slider_h3tag {
    font-size: 30px;
  }

  .home_slider .slick-next {
    left: unset;
    right: 15px;
    top: -72px;
    color: #e96230;
  }

  .home_slider .slick-prev {
    left: unset;
    right: 77px;
    top: -81px;
    color: #e96230;
  }
  .webinar-width2 .img_position_basic .image_edit {
    left: 50px;
  }
  .chartFilter {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .nav-tabs.subscription-tab .nav-link {
    width: 149px;
  }
  .nav-tabs.subscription-tab .nav-link span {
    font-size: 11px;
  }
  .dashbordrightbody-content p {
    font-size: 13px;
  }
  .dashbordrightbody-content div {
    flex-direction: column;
    align-items: center;
  }
  .Subscription-right .head h2 {
    font-size: 33px;
  }
  .profile-change {
    justify-content: center;
    padding-top: 0;
  }
  .blue_flex_sub {
    flex-direction: column;
    gap: 20px;
  }
  .blue_flex_sub p {
    font-size: 11px;
  }
  .blue_flex p {
    text-align: center;
    margin-top: 10px;
  }
  .blue_box {
    padding: 4px 4px;
  }
  .blue_box p {
    margin-top: 0;
  }
  .profile-detail-align-start {
    margin-top: 12px;
  }

  .present-profile {
    text-align: center !important;
  }

  .img_position_basic .image_edit {
    position: absolute;
    bottom: 7px;
    right: -10px;
    left: 77px;
    cursor: pointer;
  }

  .newSission {
    flex-direction: column;
  }

  .newSission .input-group {
    max-width: unset;
  }

  .newSission .error_input {
    max-width: unset;
  }

  .newSission .form-select {
    max-width: unset;
  }

  .check-select .form-check-input {
    height: 20px !important;
    width: 20px !important;
  }

  .onbtn {
    width: 100% !important;
    text-align: center;
    margin: auto;
  }

  .time-mangement {
    flex-direction: column;
  }

  .edit-close-btn {
    justify-content: center;
  }

  .btn-center {
    text-align: center;
    margin-top: 9px;
  }

  .schedule-sec p {
    width: 44%;
    margin: auto;
  }

  .img_position_basic {
    margin-bottom: 20px;
  }

  .flex-tab-11 p {
    display: none;
  }

  .tab_content_1_sub h6 {
    font-size: 16px !important;
  }

  .tab_content_1_sub2 h6 {
    font-size: 16px !important;
  }

  .tab_flex {
    flex-direction: column;
  }

  .tab-content-11-body {
    text-align: center;
  }

  .tab-img {
    width: 100% !important;
  }

  .host-head {
    flex-direction: column;
    align-items: unset !important;
  }

  .img_position_basic .image_edit {
    margin-right: auto;
    margin-left: auto;
    display: block;
    cursor: pointer;
  }

  .host-chat {
    justify-content: space-between;
  }

  .select_box_1 .form-select {
    width: 100%;
  }

  .text-align1 {
    text-align: center !important;
  }

  .tab_content_11 p {
    display: none;
  }

  .name-content {
    justify-content: center;
  }

  .img_position_basic1 {
    text-align: center;
  }

  .img_position_basic2 {
    justify-content: center;
  }

  .check-select .form-check-label {
    font-size: 16px !important;
  }

  .tab_content_1_sub {
    width: 100%;
  }

  .tab_content_1_sub2 {
    width: 100%;
  }

  .flex-tab-11 {
    flex-direction: column;
  }

  .time-schedule {
    padding: 10px;
  }

  .webinar-setting-sub p {
    text-align: center;
  }

  .webinar-setting-sub .profile_img_1 {
    margin: auto;
  }

  .configuration_tab .nav-pills {
    height: 100% !important;
    width: 100% !important;
  }

  .configuration_tab .configuration_flex {
    display: flex !important;
  }

  .configuration_tab {
    flex-direction: column;
  }

  .configuration_tab .configuration_flex .nav-item {
    width: 50%;
  }

  .configuration_flex {
    display: flex;
    flex-direction: row;
  }

  .tab_home_content {
    flex-direction: row;
    gap: 10px;
  }

  .tab_content_1 {
    height: 100%;
  }

  .configuration_tab_content p {
    display: none;
  }

  .webinar-setting-sub {
    flex-direction: column;
  }

  .tab_home_content img {
    width: 80px;
    height: 80px;
  }

  .accMain .accordion-header-content {
    gap: 10px;
  }

  .dashborad_button {
    gap: 10px;
  }

  .registration_checked {
    width: 271px;
  }

  .poll-head {
    flex-direction: column;
  }

  .polls-Add {
    width: 100%;
  }

  .poll-question {
    flex-direction: column;
  }

  .polls-qa {
    gap: 20px;
  }

  .thankyou_accbody_3 p {
    display: none;
  }

  .thankyou_accbody_3 h6 {
    font-size: 11px;
  }

  .thankyou_accbody_2 p {
    display: none !important;
  }

  .inner_home_content {
    flex-direction: column;
    text-align: center;
    gap: 5px !important;
    padding: 15px !important;
  }

  .inner_tab_content button {
    /* padding: 5px !important; */
  }

  .inner_tab_content .ticket_panel_details {
    gap: 5px !important;
  }

  .inner_tab_content .ticket_panel_details label {
    font-size: 11px !important;
  }

  .inner_support_ticket .primary_btn {
    padding: 10px !important;
    font-size: 12px !important;
  }

  .inner_support_ticket .primary_btn img {
    margin-right: 0px !important;
  }

  .inner_tab_content button {
    /* padding: 2px !important;
    font-size: 12px !important; */
  }

  .ticket_details_flex label {
    font-size: 13px !important;
  }

  .img_position_basic {
    justify-content: center !important;
  }

  .settings_display {
    text-align: center;
  }

  .analytics_select .primary_btn {
    width: 100% !important;
  }

  .dashbordrightbody-header1 {
    flex-direction: column;
    gap: 20px;
  }

  .web_display {
    flex-direction: column;
  }

  .web_display div {
    width: 100%;
  }

  .tab_button {
    flex-direction: column;
    align-items: center;
  }

  .nav-tabs .nav-link.active {
    padding: 10px 20px 10px 20px;
  }

  .nav-tabs .nav-link {
    font-size: 16px;
  }

  .tab_button .nav-item {
    padding: 10px;
  }

  .link_model p {
    text-align: center;
  }

  .modal_form {
    text-align: center;
  }

  .register_page .invite_details_head {
    padding-top: 0 !important;
  }

  .settings_display .img_position_basic .image_edit {
    right: -22px;
  }

  .forgetpassword1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .webinar-width2 .img_position_basic .image_edit {
    right: -9px;
  }

  .inner_tab_content .border_tab {
    border: 1px solid transparent;
    padding: 0;
  }

  .inner_tab_content button.active {
    background: transparent !important;
    color: #11777e !important;
  }

  .home_slider .slick-next {
    left: unset;
    right: 12px;
    top: -70px;
    color: #e96230;
  }

  .home_slider .slick-prev {
    left: unset;
    right: 80px;
    top: -79px;
    color: #e96230;
  }

  .slider_h3tag {
    font-size: 30px;
  }
  .name-profile {
    text-align: start;
  }
  .innerfooter {
    flex-direction: column;
  }
  .navbar_right .primary_btn2 {
    min-width: 85px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .navbar_right .primary_btn3 {
    min-width: 85px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .navbar-brand .img-fluid {
    max-width: 110px;
  }
  .datapick {
    width: 100%;
    gap: 0;
  }
  .grid_Row_list .grid_Row_list_child .datapick {
    width: auto !important;
  }
  .datapick .react-datepicker-wrapper {
    width: 100%;
  }
  .titleCount {
    flex-direction: column;
    gap: 12px;
  }
  .pagination-flex {
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
  }
  .innerfooter p {
    padding: 0;
    text-align: center;
  }
  #itemsPerPage {
    margin-left: 7px;
  }
  .grid_Row_list {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .icon_grid_list {
    width: 100%;
  }
  .icon_grid_list .primary_btn {
    width: 100%;
  }
  .dashbord-right-header {
    flex-direction: column;
    gap: 20px;
  }
  .dashbord-right-header .datapick {
    flex-direction: column;
    gap: 20px;
  }
  .dashbord-right-header div.mobFullwidth {
    width: 100%;
  }
  .dashbord-right-header .datapick .primary_btn {
    width: 100%;
  }
  .instant-meeting .white-box {
    padding: 20px !important;
  }
  .instant-meeting .white-box .white-box {
    padding: 0 !important;
    box-shadow: none !important;
    border: 0 !important;
  }
  .instant-meeting-box .white-box h6 {
    white-space: unset !important;
  }
  .formcheck_register .form-check-label {
    font-size: 14px;
  }
  .instant-meeting-head .brand_logo {
    max-width: 150px;
  }
  .registration_nav_sec {
    padding-top: 0;
  }
  .custom-scrollbar {
    height: 125px !important;
  }
  .temp_config {
    gap: 12px;
  }
  .temp_config li {
    width: 100%;
  }
  .temp_config li button.nav-link {
    margin-right: 0 !important;
    display: block;
    width: 100%;
  }
  .registration_radio img {
    padding-left: 0;
  }
  .Auto_subscription .nav-pills {
    flex-direction: column;
  }
  .tab_flex > div {
    width: 100% !important;
  }
  .nav_link1 {
    width: 100%;
  }
  .pre_notification .pre_notification_list .list_msg::before {
    display: none;
  }
  .pre_notification .pre_notification_list li {
    padding-left: 0;
  }
  .pre_notification .pre_notification_list::after,
  .pre_notification .pre_notification_1 li::before,
  .pre_notification .pre_notification_1 li::after {
    display: none;
  }
  .dashbordrightbody-list1 {
    padding: 12;
  }
}
@media (max-width: 450px) {
  .home_slider .slick-next {
    left: unset;
    right: 12px;
    top: -76px;
    color: #e96230;
  }

  .home_slider .slick-prev {
    left: unset;
    right: 72px;
    top: -85px;
    color: #e96230;
  }
}
